import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "page-create-follow" }
const _hoisted_2 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_detail_header = _resolveComponent("detail-header")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_MdEditor = _resolveComponent("MdEditor")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "title" }, " 新增任务跟进 ", -1)),
    _createVNode(_component_detail_header, { rows: _ctx.headerRows }, null, 8, ["rows"]),
    _createVNode(_component_el_form, {
      ref: "form",
      model: _ctx.formData,
      rules: _ctx.formDataRules,
      "label-width": "120px",
      class: "annoucement__form"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 12 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: "人员：",
                  prop: "user_id"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: _ctx.formData.user_id,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.user_id) = $event)),
                      disabled: !_ctx.canSetUserFormData,
                      style: {"width":"100%"},
                      clearable: "",
                      placeholder: "请选择人员"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options.users, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item.id,
                            label: item.name,
                            value: item.id
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue", "disabled"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: _ctx.curUser && _ctx.curUser.is_manager ? 8 : 12
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: "考核项：",
                  prop: "kpi_id"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: _ctx.formData.kpi_id,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.kpi_id) = $event)),
                      style: {"width":"100%"},
                      clearable: "",
                      disabled: !_ctx.formData.user_id,
                      placeholder: "请选择考核项",
                      onChange: _ctx.kpiChange
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.kpiList, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item.id,
                            label: item.name,
                            value: item.id
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue", "disabled", "onChange"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["span"]),
            (_ctx.curUser && _ctx.curUser.is_manager)
              ? (_openBlock(), _createBlock(_component_el_col, {
                  key: 0,
                  span: 4
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, { label: "管理考核项" }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, [
                          _createVNode(_component_el_switch, {
                            modelValue: _ctx.isManager,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isManager) = $event)),
                            "inline-prompt": "",
                            loading: _ctx.switchLoading,
                            "active-color": "#13ce66",
                            "inactive-color": "#ff4949",
                            "active-text": "是",
                            "inactive-text": "否",
                            onChange: _ctx.handleSwitchChange
                          }, null, 8, ["modelValue", "loading", "onChange"])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 12 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: "阶段：",
                  prop: "stage_id"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: _ctx.formData.stage_id,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.stage_id) = $event)),
                      style: {"width":"100%"},
                      clearable: "",
                      placeholder: "请选择阶段"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options.stages, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item.id,
                            label: item.name,
                            value: item.id
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 12 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: "评分：",
                  prop: "score"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.formData.score,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.score) = $event)),
                      modelModifiers: { number: true },
                      type: "number",
                      placeholder: _ctx.options.scoreConfig?.placeholder
                    }, null, 8, ["modelValue", "placeholder"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 12 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: "标题：",
                  prop: "title"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.formData.title,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.title) = $event)),
                      type: "text",
                      placeholder: "请输入标题",
                      maxlength: "50"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "正文：",
          prop: "remark"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_MdEditor, {
              ref: "markdown",
              modelValue: _ctx.formData.remark,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.remark) = $event)),
              style: {"width":"1000px"}
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_button, {
        plain: "",
        onClick: _ctx.handleSubmit
      }, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [
          _createTextVNode(" 提交 ")
        ])),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}