
import {
  computed,
  defineComponent, onMounted, PropType, reactive, ref,
} from 'vue';
import { CrudColumn, CrudOptions } from './crud.type';
import filterPane from './FilterPane.vue';
import crudPagination from './CrudPagination.vue';
import tablePane from './TablePane.vue';

export default defineComponent({
  name: 'LxCrud',
  components: {
    filterPane,
    crudPagination,
    tablePane,
  },
  inheritAttrs: false,
  props: {
    options: {
      type: Object as PropType<CrudOptions>,
      required: true,
    },
    immediate: {
      type: Boolean,
      default: true,
    },
    pagination: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const { options, immediate } = reactive(props);
    // 表格数据
    const tableData = ref<Array<any>>([]);
    // 搜索条件配置
    const searchOptions: CrudColumn[] = [];
    // 表格配置
    const tableOptions: CrudColumn[] = [];
    // 快捷切换栏配置
    const tabsOptions = options?.tabs;
    const defaultTabs = tabsOptions?.filter((el: any) => el.default) ? tabsOptions?.filter((el: any) => el.default)[0].value : tabsOptions && tabsOptions[0].value;

    // 检索条件
    const formInline = reactive<{[key: string]: any}>({
      page: props.pagination ? options.page || 1 : null,
      count: props.pagination ? options.count || 10 : null,
      status: defaultTabs || null,
    });

    // 分页总数
    const total = ref(0);

    // 搜索
    const search = async () => {
      options.fetch({ ...formInline }).then((res: Record<string, any>) => {
        const { tableList, totalNum } = res;
        tableData.value = tableList;
        total.value = totalNum || 0;
      });
    };

    // 重置页码搜索
    const refresh = (): void => {
      formInline.page = props.pagination ? 1 : null;
      search();
    };

    // 更新页码搜索
    const pageChange = (page: number) => {
      formInline.page = page;
      search();
    };

    // 配置分发
    options?.column?.forEach((el: any) => {
      el.search && searchOptions.push(el);
      !el.hidden && tableOptions.push(el);
    });

    // 处理插槽
    const transferSlots = Object.keys(context.slots);
    const searchTransferSlots = transferSlots.filter(el => /.*?SearchSlot/g.test(el));
    const tableTransferSlots = transferSlots.filter(el => /.*?TableSlot/g.test(el));
    onMounted(() => {
      if (immediate) {
        search();
      }
    });

    // 是否展示tab栏
    const showTabs = computed(() => context.slots.crudStatusBtn || (tabsOptions && tabsOptions.length > 0));

    return {
      searchOptions,
      tableOptions,
      tabsOptions,
      formInline,
      refresh,
      search,
      tableData,
      total,
      searchTransferSlots,
      tableTransferSlots,
      showTabs,
      pageChange,
    };
  },

});

