/* src/common/enum.ts 存放一些通用的字典 */

// Bug等级
export const BugLevelEnum = [
  {
    value: 1,
    label: '提示',
  },
  {
    value: 2,
    label: '一般',
  },
  {
    value: 3,
    label: '严重',
  },
  {
    value: 4,
    label: '致命',
  },
];
// Bug发现阶段
export const BugStageEnum = [
  {
    value: 1,
    label: '测试',
  },
  {
    value: 2,
    label: '体验',
  },
  {
    value: 3,
    label: '线上',
  },
];
// 任务类型
export const TaskTypeEnum = [
  { label: '业务迭代', value: 'project' },
  { label: '优化重构', value: 'optimization' },
  { label: '缺陷', value: 'bug' },
  { label: '其他', value: 'custom' },
];
// 提测bug情况的任务类型
export const TestStageTaskTypeEnum = [
  { label: '业务迭代', value: 'project' },
  { label: '优化重构', value: 'optimization' },
];

// 提测bug情况的角色类型
export const TestStageUserTypeEnum = [
  { label: '前端', value: 'frontend' },
  { label: '后端', value: 'backend' },
];
