import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "component-stage-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_auth = _resolveDirective("auth")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_table, {
      data: _ctx.tableData,
      border: "",
      size: "medium",
      class: "table",
      "row-class-name": "table__column"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "id",
          label: "id",
          "min-width": "20"
        }),
        _createVNode(_component_el_table_column, {
          prop: "stage",
          label: "阶段名称",
          "min-width": "20"
        }),
        _createVNode(_component_el_table_column, {
          prop: "followup_counts",
          label: "跟进数量",
          "min-width": "20"
        }),
        _createVNode(_component_el_table_column, {
          label: "时间",
          "min-width": "20"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", null, _toDisplayString(scope.row.started_at || '--'), 1),
            _createElementVNode("div", null, _toDisplayString(scope.row.finished_at || '--'), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "添加信息",
          "min-width": "20"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", null, _toDisplayString(scope.row.creator_name || '--'), 1),
            _createElementVNode("div", null, _toDisplayString(scope.row.created_at || '--'), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "address",
          label: "删除信息",
          "min-width": "20"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", null, _toDisplayString(scope.row.deletor_name || '--'), 1),
            _createElementVNode("div", null, _toDisplayString(scope.row.deleted_at || '--'), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "principal_name",
          label: "负责人",
          "min-width": "20"
        }),
        _createVNode(_component_el_table_column, {
          label: "操作",
          width: "80"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", null, [
              _createVNode(_component_el_button, {
                type: "text",
                size: "small",
                onClick: ($event: any) => (_ctx.goFollow(scope.row))
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode(" 查看跟进 ")
                ])),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _createElementVNode("div", null, [
              (_ctx.status === 'active')
                ? _withDirectives((_openBlock(), _createBlock(_component_el_button, {
                    key: 0,
                    type: "text",
                    size: "small",
                    onClick: ($event: any) => (_ctx.goCreateFollow(scope.row))
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode(" 新增跟进 ")
                    ])),
                    _: 2
                  }, 1032, ["onClick"])), [
                    [_directive_auth]
                  ])
                : _createCommentVNode("", true)
            ]),
            (_ctx.status === 'active')
              ? (_openBlock(), _createBlock(_component_el_popconfirm, {
                  key: 0,
                  "confirm-button-text": "确定",
                  "cancel-button-text": "取消",
                  icon: _ctx.InfoFilled,
                  "icon-color": "red",
                  title: "确认删除该阶段",
                  onConfirm: ($event: any) => (_ctx.confirmDelete(scope.row))
                }, {
                  reference: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      type: "text",
                      size: "small"
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode(" 删除 ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1032, ["icon", "onConfirm"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"])
  ]))
}