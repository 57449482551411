import { ref } from 'vue';
import { getUserRoleList } from '@/api';
import { RoleRow } from '@/types/personnel/personnel';

const roleList = ref<Array<RoleRow>>([]); // 角色列表

export const useRolesList = () => {
  // 获取角色列表
  const remoteRolesList = async () => {
    const { code, data } = await getUserRoleList();
    if (code === 0) {
      roleList.value = data;
    }
  };
  return {
    roleList,
    remoteRolesList,
  };
};
