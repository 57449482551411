
import { defineComponent, PropType, reactive } from 'vue';
import { CrudColumn } from '@/components/Crud/crud.type';
import { useDateFormat } from '@/hooks/useDateFormat';

export default defineComponent({
  props: {
    filterData: {
      type: Array as PropType<CrudColumn[]>,
      default: () => [],
    },
    queryForm: {
      type: Object as any,
      default: () => {},
    },
  },
  emits: ['search'],
  setup(props, ctx) {
    const formInline: {[key: string]: any} = reactive(props.queryForm as any);
    const handleDateChange = (times: any, searchItem: CrudColumn | any) => {
      for (let i = 0; i < searchItem?.searchKey?.length; i++) {
        formInline[searchItem?.searchKey[i]] = times ? useDateFormat(times[i]) : '';
      }
    };
    // 填充默认值
    props.filterData.forEach((el: CrudColumn) => {
      el.defaultValue && (formInline[el.prop] = el.defaultValue);
      // 如果是多数据默认值则通过遍历方式处理
      if (Array.isArray(el.defaultValue) && Array.isArray(el.searchKey)) {
        handleDateChange(el.defaultValue, el);
      }
    });
    // 查询
    const search = () => {
      ctx.emit('search');
    };

    // 远程搜索
    const remoteFetch = (query: any, item: any) => {
      if (!query && item?.enum?.length > 0 && formInline[item.prop]) {
        return;
      }
      item.remoteMethod(query).then((list: any) => {
        item.enum = list;
      });
    };
    return {
      formInline,
      handleDateChange,
      search,
      remoteFetch,
    };
  },
});
