import { WorkInMember } from '@/types/task/member';
import router from '@/router';

type config = 'member' | 'stage' | 'follow' | 'create' | 'taskBug'

interface I_HeaderRow {
  label: string,
  value: any
}

/**
 * @description 可配置的页头超链接hook
 * @export
 * @param {WorkInMember} work 具体迭代信息
 * @param {config[]} configs 可选渲染链接的配置项
 * @returns 组件detail-header所需配置
 */
export function useHeaderRows (
  work: WorkInMember,
  configs: config[],
): I_HeaderRow[] {
  const { id, name, url, design_url } = work;
  const methodList = {
    member: {
      label: '任务人员',
      method: () => { router.push(`/member?id=${id}`); },
    },
    stage: {
      label: '任务阶段',
      method: () => { router.push(`/stage?id=${id}`); },
    },
    follow: {
      label: '任务跟进',
      method: () => { router.push(`/follow?id=${id}`); },
    },
    create: {
      label: '新增跟进',
      method: () => { router.push(`/create-follow?id=${id}`); },
    },
    taskBug: {
      label: 'Bug情况',
      method: () => { router.push(`/taskBug?id=${id}`); },
    },
  };

  const options = configs.map(config => Reflect.get(methodList, config)); // 根据配置获取所需的method

  // 基础页头所需信息
  const basic = [
    { label: 'ID', value: id },
    { label: '名称', value: name, method: () => { window.open(url); }, designUrl: design_url, workId: id },
  ];

  /*
   * 如果无配置信息 则不需要底部超链接快捷方式一栏 如：创建跟进页面
   */
  return options.length
    ? ([
      ...basic,
      {
        label: '快捷方式',
        value: options,
      }])
    : basic;
}
