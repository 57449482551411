import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_MdEditor = _resolveComponent("MdEditor")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    ref: "taskBugForm",
    model: _ctx.formData,
    rules: _ctx.fromDataRules,
    "label-width": "100px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 12 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: "人员:",
                prop: "work_user_id"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: _ctx.formData.work_user_id,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.work_user_id) = $event)),
                    style: {"width":"100%"},
                    clearable: "",
                    placeholder: "请选择人员",
                    filterable: "",
                    remote: "",
                    "remote-method": _ctx.remoteFetch,
                    onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.remoteFetch(''))),
                    onClear: _cache[2] || (_cache[2] = ($event: any) => (_ctx.remoteFetch('')))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userList, (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.id,
                          label: item.name,
                          value: item.id
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "remote-method"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { span: 12 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: "阶段:",
                prop: "stage"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: _ctx.formData.stage,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.stage) = $event)),
                    style: {"width":"100%"},
                    clearable: "",
                    placeholder: "请选择阶段",
                    onChange: _ctx.changeStage
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.BugStageEnum, (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.value,
                          label: item.label,
                          value: item.value
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "onChange"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (_ctx.formData.stage !== 2)
            ? (_openBlock(), _createBlock(_component_el_col, {
                key: 0,
                span: 12
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, {
                    label: "Bug等级:",
                    prop: "level"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, {
                        modelValue: _ctx.formData.level,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.level) = $event)),
                        style: {"width":"100%"},
                        clearable: "",
                        placeholder: "请选择Bug等级"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.BugLevelEnum, (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: item.value,
                              label: item.label,
                              value: item.value
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_el_col, { span: 12 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: "数量:",
                prop: "count"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input_number, {
                    modelValue: _ctx.formData.count,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.count) = $event)),
                    style: {"width":"100%"},
                    min: 0.1,
                    controls: false,
                    precision: 1
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, {
        label: "备注：",
        prop: "remark"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MdEditor, {
            ref: "markdown",
            modelValue: _ctx.formData.remark,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.remark) = $event)),
            style: {"width":"100%"}
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_button, { onClick: _ctx.closeDialog }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode(" 取 消 ")
          ])),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: _ctx.submit
        }, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [
            _createTextVNode(" 确 定 ")
          ])),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}