import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "page-task-index" }
const _hoisted_2 = { class: "tab-box" }
const _hoisted_3 = { class: "header" }
const _hoisted_4 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_current_table = _resolveComponent("current-table")!
  const _component_pagination = _resolveComponent("pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "title" }, " 当前任务 ", -1)),
    _createVNode(_component_el_form, {
      inline: "",
      model: _ctx.formInline,
      class: "form-inline",
      rules: _ctx.rules
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: "人员:",
          prop: "userName"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_autocomplete, {
              modelValue: _ctx.autoComplete.userName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.autoComplete.userName) = $event)),
              class: "inline-input",
              "select-when-unmatched": "",
              "trigger-on-focus": "",
              "fetch-suggestions": _ctx.queryMember,
              placeholder: "人员",
              debounce: 100,
              onSelect: _ctx.handleMemberSelect
            }, null, 8, ["modelValue", "fetch-suggestions", "onSelect"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "角色:" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              modelValue: _ctx.formInline.user_type,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formInline.user_type) = $event)),
              clearable: "",
              placeholder: "角色"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roleList, (role) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: role.code,
                    label: role.name,
                    value: role.code
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "任务类型:" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              modelValue: _ctx.formInline.type,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formInline.type) = $event)),
              clearable: "",
              placeholder: "任务类型"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typeList, (type) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: type.label,
                    label: type.label,
                    value: type.value
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "任务时间:" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_date_picker, {
              modelValue: _ctx.datePicker,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.datePicker) = $event)),
              "unlink-panels": "",
              type: "datetimerange",
              "range-separator": "至",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "完成时间:" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_date_picker, {
              modelValue: _ctx.finishDatePicker,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.finishDatePicker) = $event)),
              type: "datetimerange",
              "range-separator": "至",
              "unlink-panels": "",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _ctx.refresh
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode(" 查询 ")
              ])),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_button, {
          type: "primary",
          plain: "",
          onClick: _ctx.goExport
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode(" 导出 ")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      _createVNode(_component_current_table, {
        "table-data": _ctx.tableData,
        onRefresh: _ctx.refresh
      }, null, 8, ["table-data", "onRefresh"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_pagination, {
        "page-info": _ctx.pageInfo,
        onPageChange: _ctx.pageChange
      }, null, 8, ["page-info", "onPageChange"])
    ])
  ]))
}