
import { Work } from '@/types/task/work';
import { defineComponent, PropType } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

interface UserInfo {
  id: number;
  name: string;
  type: 'currentWork' | 'personnel';
  type_code?: string | '';
}

export default defineComponent({
  props: {
    // 表格数据
    tableData: {
      type: Array as PropType<Array<Work>>,
      required: true,
      default: () => [],
    },
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    // 设置跳转指定用户任务列表
    const setUser = (payload: UserInfo) => store.commit('personnel/setUserDetail', payload);
    // 跳转具体的tapd / 自定义URL
    const goTask = ({ id, name }: UserInfo) => {
      setUser({ id, name, type: 'personnel' });
      router.push('/');
    };
    // 跳转新建跟进
    const goCreateFollow = ({ id }: UserInfo) => {
      router.push({
        name: 'CreateFollow',
        query: {
          uid: id,
          type: 'fromUser',
        },
      });
    };
    return {
      goTask,
      goCreateFollow,
    };
  }, // 跳转具体的tapd / 自定义URL
});
