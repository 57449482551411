
import { getLoginUrl } from '@/api';
import { Message } from '@/common/utils';
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  setup() {
    const route = useRoute();
    const { redirect } = route.query;
    const next = redirect || `${window.location.protocol}//${window.location.host}`;
    const loginUrl = ref('');
    const goScanCode = () => {
      if (loginUrl.value) {
        window.location.href = loginUrl.value;
      } else {
        Message('未获取到登录跳转链接 请刷新重试', 'error');
      }
    };

    const init = async () => {
      const { data, code } = await getLoginUrl({ next });
      if (code === 0) {
        loginUrl.value = data;
      } else {
        Message('未获取到登录跳转链接 请刷新重试', 'error');
      }
    };

    onMounted(() => {
      init();
    });

    return {
      goScanCode,
    };
  },
});
