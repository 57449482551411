
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { createWorkBug, getTaskMember } from '@/api';
import { convertIntInFloat, Message } from '@/common/utils';
import { TaskBugFormBody } from '@/modules/task/taskBug/type';
import { UserInWork } from '@/types/task/member';
import { useFormRequired } from '@/hooks/useFormRequired';
import MdEditor from '@/components/Markdown/MdEditor.vue';
import { BugLevelEnum, BugStageEnum } from '@/common/enum';

export default defineComponent({
  name: 'AddTaskBug',
  components: {
    MdEditor,
  },
  emits: ['close'],
  setup(props, ctx) {
    const route = useRoute();
    const { id = '' } = route.query;
    // 关闭弹窗
    const closeDialog = () => {
      ctx.emit('close', true);
    };
    // 表单dom
    const taskBugForm = ref();
    // 默认项
    const defaultDialogFrom: TaskBugFormBody = {
      count: undefined,
      level: null,
      remark: null,
      stage: 1,
      work_user_id: null,
    };
    // 表单内容
    const formData = ref<TaskBugFormBody>({ ...defaultDialogFrom });
    // 远程搜索人员列表
    const userList = ref<Array<UserInWork>>([]);
    const remoteFetch = async (query: any) => {
      const { code, data } = await getTaskMember(id, { name: query, status: 'active' });
      if (code === 0) {
        userList.value = data.users;
      } else {
        Message('获取用户失败', 'error');
      }
    };
    // 表单规则
    const fromDataRules = {
      ...useFormRequired({
        work_user_id: '人员',
        level: '等级',
        count: '数量',
        stage: '阶段',
      }),
    };
    // 选择阶段 - 当选择2时清空bug等级
    const changeStage = (val: number | '') => {
      if (val === 2) {
        formData.value.level = null;
      }
    };
    // 提交表单
    const submit = () => {
      taskBugForm.value.validate(async(valid: any) => {
        if (!valid) return;
        const reqData = JSON.parse(JSON.stringify(formData.value));
        delete reqData.work_user_id;
        reqData.count = convertIntInFloat(reqData.count);
        const { code } = await createWorkBug(id, formData.value.work_user_id, reqData);
        if (code === 0) {
          Message('创建成功');
          taskBugForm.value.resetField;
          closeDialog();
        }
      });
    };

    return {
      submit,
      remoteFetch,
      changeStage,
      fromDataRules,
      formData,
      BugLevelEnum,
      BugStageEnum,
      userList,
      taskBugForm,
      closeDialog,
    };
  },
});
