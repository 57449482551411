
import { deleteStage } from '@/api';
import { Message } from '@/common/utils';
import { StatusType } from '@/types';
import { StageRow } from '@/types/task/stage';
import { defineComponent, PropType } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  props: {
    // 当前状态
    status: {
      type: String as PropType<StatusType>,
      required: true,
      default: 'active',
    },
    // 表格数据
    tableData: {
      type: Array as PropType<Array<StageRow>>,
      required: true,
      default: () => [],
    },
  },
  emits: ['refresh'],
  setup(_, { emit }) {
    const router = useRouter();
    // 删除任务成员
    const confirmDelete = async ({ work_id, id: sid }: StageRow) => {
      const { code } = await deleteStage(work_id, sid);
      if (code === 0) {
        Message('删除成功');
        emit('refresh');
      }
    };
    // 查看跟进
    const goFollow = ({ work_id, id: sid }: StageRow) => {
      router.push(`/follow?id=${work_id}&sid=${sid}`);
    };

    // 新增跟进
    const goCreateFollow = ({ work_id, id: sid }: StageRow) => {
      router.push(`/create-follow?id=${work_id}&sid=${sid}`);
    };

    return {
      confirmDelete,
      goFollow,
      goCreateFollow,
    };
  },
});
