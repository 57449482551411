import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "component-detail-header" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "component-detail-header__url" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 1,
  class: "component-detail-header__url--edit"
}
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, { class: "form" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row) => {
          return (_openBlock(), _createBlock(_component_el_form_item, {
            key: row.label,
            label: row.label + ':'
          }, {
            default: _withCtx(() => [
              (Array.isArray(row.value))
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.value, (link) => {
                      return (_openBlock(), _createElementBlock("span", {
                        key: link.label,
                        class: "link",
                        onClick: link.method
                      }, _toDisplayString(link.label), 9, _hoisted_3))
                    }), 128))
                  ]))
                : (row.label === '名称')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("span", {
                        class: "link",
                        onClick: row.method
                      }, _toDisplayString(row.value), 9, _hoisted_5),
                      _createElementVNode("div", _hoisted_6, [
                        (!_ctx.isDocUrlEdit)
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 0,
                              class: _normalizeClass({'link': row.designUrl}),
                              onClick: ($event: any) => (_ctx.handleUrlClick(row))
                            }, "设计文档", 10, _hoisted_7))
                          : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                              _createVNode(_component_el_form, {
                                ref_for: true,
                                ref: "formRef",
                                model: _ctx.docUrlForm,
                                rules: _ctx.rules,
                                "label-width": "0",
                                style: {"max-width":"600px"}
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_form_item, {
                                    label: "",
                                    prop: "design_url"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_input, {
                                        modelValue: _ctx.docUrlForm.design_url,
                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.docUrlForm.design_url) = $event)),
                                        style: {"width":"480px"},
                                        placeholder: "请输入文档URL，URL长度不超过256"
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }, 8, ["model", "rules"])
                            ])),
                        _createVNode(_component_el_button, {
                          onClick: ($event: any) => (_ctx.handleConfirmDocUrl(row))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.isDocUrlEdit ? '保存' : '修改'), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]),
                        _withDirectives(_createVNode(_component_el_button, {
                          style: {"margin-left":"0"},
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isDocUrlEdit = false))
                        }, {
                          default: _withCtx(() => _cache[2] || (_cache[2] = [
                            _createTextVNode(" 取消 ")
                          ])),
                          _: 1
                        }, 512), [
                          [_vShow, _ctx.isDocUrlEdit]
                        ])
                      ])
                    ]))
                  : (row.method)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 2,
                        class: "link",
                        onClick: row.method
                      }, _toDisplayString(row.value), 9, _hoisted_9))
                    : (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(row.value), 1))
            ]),
            _: 2
          }, 1032, ["label"]))
        }), 128)),
        _renderSlot(_ctx.$slots, "others", {}, undefined, true)
      ]),
      _: 3
    })
  ]))
}