
import { defineComponent } from 'vue';
import { EnumObjectData } from '@/components/Crud/crud.type';

export default defineComponent({
  name: 'TablePane',
  props: {
    tableData: {
      type: Array,
    },
    tableOptions: {
      type: Array,
    },
  },
  setup() {
    // 过滤数据
    const columnFilter = (enumData: Array<EnumObjectData>, data: any) => enumData.find(el => el.value === data)?.label || '';
    return {
      columnFilter,
    };
  },
});
