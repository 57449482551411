
import {
  defineComponent, ref, reactive, getCurrentInstance,
} from 'vue';
import { Tapd, Task } from '@/types/task/create';
import MdEditor from '@/components/Markdown/MdEditor.vue';
import {
  getTapdTask, createTapdTask, createCustomWork, createTapdBug, getTapdBugs,
} from '@/api/index';
import { Message } from '@/common/utils';
import { useRouter } from 'vue-router';
import { useFormRequired } from '@/hooks/useFormRequired';

export default defineComponent({
  components: {
    MdEditor,
  },
  setup () {
    const ctx = getCurrentInstance()?.proxy;
    const router = useRouter();
    const isRequired = useFormRequired({
      name: '任务名称',
      url: '相关URL',
      remark: '正文',
    }); // 表单非空字段规则映射
    const activeName = ref<Task>('project'); // 当前tab页名称
    const tabs = [
      { label: '产品迭代', name: 'project' },
      { label: '优化重构', name: 'optimization' },
      { label: 'Bug修复', name: 'bug' },
      { label: '自定义任务', name: 'custom' },
    ]; // 渲染tabs数组
    const taskArr = ref<Array<Tapd & { value: string }>>([]); // 模糊搜索用户数组

    // 下拉选择迭代校验规则
    const fillterTask = (rule: any, value: string, callback: (...arg: any) => any) => {
      const index = taskArr.value.findIndex(task => task.value === value);
      if (value === '') {
        callback(new Error('请添加迭代'));
      } else if (index === -1) {
        callback(new Error('请从下拉选择中添加迭代'));
      } else {
        callback();
      }
    };
    const formData = ref({
      id: -1,
      title: '',
      name: '',
      url: '',
      remark: '',
    }); // 表单内容
    const tapdParams = reactive({
      page: 1,
      count: 10,
      name: '',
    }); // 模糊搜索tapd任务字段

    // 查找tapd上的迭代
    const fetchTapdTask = async (name: string) => {
      const isBug = activeName.value === 'bug';
      const params = { ...tapdParams, name };
      const { code, data } = await (isBug ? getTapdBugs({ ...params }) : getTapdTask({ ...params, type: activeName.value }));
      if (code === 0) {
        return [...data.tapds];
      }
      return [];
    };

    // 模糊搜索迭代
    const querySearchAsync = async (queryString: string, cb: (...arg: any) => any) => {
      const title = await fetchTapdTask(queryString);
      taskArr.value = title.map(item => ({
        ...item,
        value: item.name,
      }));
      cb(taskArr.value);
    };

    const formDataRules = reactive({
      title: [
        { validator: fillterTask, required: true },
      ],
      ...isRequired,
    }); // 表单规则

    // 清空表单
    const clearForm = ({ paneName }: { paneName: string }) => {
      const formName = `submitForm${paneName}`;
      formData.value = {
        id: -1,
        title: '',
        name: '',
        url: '',
        remark: '',
      };
      (ctx?.$refs[`${activeName.value}Markdown`] as any).setMarkdown('');
      (ctx?.$refs[formName] as any).resetFields();
    };

    // 表单信息统一回调
    const formMessage = (formName: string, code: number) => {
      if (code === 0) {
        Message('保存成功');
        (ctx?.$refs[formName] as any).resetFields();
        router.push('/');
      } else {
        Message('保存失败', 'error');
      }
    };

    // 提交任务
    const handleSubmit = async (formName: string) => {
      const {
        id = -1, name = '', url = '', remark = '',
      } = { ...formData.value };
      const [normal, custom] = [{ name, remark }, { name, url, remark }];
      const isCustom = activeName.value === 'custom';
      if (!isCustom) {
        const isBug = activeName.value === 'bug';
        const { code } = await (isBug ? createTapdBug(id, normal) : createTapdTask(id, normal));
        formMessage(formName, code);
      } else {
        const { code } = await createCustomWork(custom);
        formMessage(formName, code);
      }
    };

    // 表单校验
    const submit = (formName: string) => {
      (ctx?.$refs[formName] as any)[0].validate((valid: any) => {
        if (valid) {
          handleSubmit(formName);
        } else {
          return false;
        }
      });
    };

    // 选择模糊搜索后的回调
    const handleSelect = ({
      id, url, remark, value: title,
    }: Tapd & { value: string }) => {
      formData.value = {
        ...formData.value,
        id,
        url,
        remark,
        title,
        name: title,
      };
      (ctx?.$refs[`${activeName.value}Markdown`] as any).setMarkdown(remark);
    };

    return {
      submit,
      clearForm,
      querySearchAsync,
      handleSelect,
      activeName,
      tabs,
      formData,
      formDataRules,
      handleSubmit,
    };
  },
});
