
import { getUser } from '@/api';
import { Member } from '@/types/task/member';
import {
  defineComponent, onMounted, onUnmounted, reactive, ref,
} from 'vue';
import Pagination from '@/components/pagination.vue';
import { useStore } from 'vuex';
import { useRolesList } from '@/hooks/useRolesList';
import IndexTable from './components/index-table.vue';

export default defineComponent({
  components: {
    IndexTable,
    Pagination,
  },
  setup() {
    const store = useStore();
    // 搜索条件表单
    const formInline = reactive({
      id: null as null | number, // 默认没有的情况
      mobile_number: null as null | number, // 默认没有的情况
      email: '',
      status: 'active',
      name: '',
      type: '',
      is_manager: '',
    });
    // 模糊匹配自动填充字段
    const autoComplete = reactive({
      userName: '',
    });
    const tableData = ref<Array<Member>>([]);
    const tabs = [
      { label: '正常', name: 'active' },
      { label: '已删除', name: 'deleted' },
    ]; // 渲染tabs数组

    // 分页信息
    const pageInfo = reactive({
      currentPage: 1,
      pageSize: 10,
      total: 40,
    });

    // 获取数据
    const search = async () => {
      const { code, data } = await getUser({
        ...formInline,
        page: pageInfo.currentPage,
        count: pageInfo.pageSize,
      });
      if (code === 0) {
        pageInfo.total = data.total_count;
        tableData.value = data.users;
      }
    };

    // 分页回调
    const pageChange = ({ currentPage }: { currentPage: number }): void => {
      pageInfo.currentPage = currentPage;
      search();
    };

    // 重新筛选数据
    const refresh = (): void => {
      pageInfo.currentPage = 1;
      search();
    };

    // 获取角色列表
    const { roleList, remoteRolesList } = useRolesList();

    onMounted(() => {
      const uid = store.state.personnel.user.id;
      if (uid) {
        formInline.id = uid;
      }
      remoteRolesList();
      search();
    });

    onUnmounted(() => {
      // 从当前任务过来的 离开页面重置筛选条件
      if (store.state.personnel.user.type === 'currentWork') {
        store.commit('personnel/setUserDetail', { id: null, name: '' });
      }
    });

    return {
      tabs,
      tableData,
      pageInfo,
      autoComplete,
      roleList,
      formInline,
      search,
      refresh,
      pageChange,
    };
  },
});
