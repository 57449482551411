import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vShow as _vShow, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "task-bug" }
const _hoisted_2 = { class: "task-bug__empty" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_detail_header = _resolveComponent("detail-header")!
  const _component_document_checked = _resolveComponent("document-checked")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_ellipsis = _resolveComponent("ellipsis")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!
  const _component_lx_crud = _resolveComponent("lx-crud")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_auth = _resolveDirective("auth")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_detail_header, {
      rows: _ctx.headerRows,
      onRefresh: _ctx.handleRefresh
    }, {
      others: _withCtx(() => [
        (_ctx.work)
          ? _withDirectives((_openBlock(), _createBlock(_component_el_form_item, { key: 0 }, {
              default: _withCtx(() => [
                _cache[2] || (_cache[2] = _createElementVNode("span", { class: "link" }, "任务无BUG：", -1)),
                _createElementVNode("span", null, [
                  _createVNode(_component_el_switch, {
                    modelValue: _ctx.work.is_no_bug,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.work.is_no_bug) = $event)),
                    "inline-prompt": "",
                    loading: _ctx.switchLoading,
                    "active-color": "#13ce66",
                    "inactive-color": "#ff4949",
                    "active-text": "是",
                    "inactive-text": "否",
                    onChange: _ctx.handleSwitchChange
                  }, null, 8, ["modelValue", "loading", "onChange"])
                ])
              ]),
              _: 1
            })), [
              [_directive_auth]
            ])
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["rows", "onRefresh"]),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_icon, {
        color: '#409EFF',
        size: 80
      }, {
        default: _withCtx(() => [
          _createVNode(_component_document_checked)
        ]),
        _: 1
      }),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "font" }, " 当前任务无BUG ", -1))
    ], 512), [
      [_vShow, _ctx.isNoBug]
    ]),
    _withDirectives(_createVNode(_component_lx_crud, {
      ref: "crud",
      options: _ctx.options,
      immediate: "",
      pagination: false
    }, {
      crudStatusBtn: _withCtx(() => [
        _withDirectives((_openBlock(), _createBlock(_component_el_button, {
          type: "primary",
          class: "header",
          plain: "",
          onClick: _ctx.goCreate
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode(" 新增 ")
          ])),
          _: 1
        }, 8, ["onClick"])), [
          [_directive_auth]
        ])
      ]),
      remarkTableSlot: _withCtx((scope) => [
        _createVNode(_component_ellipsis, {
          "over-type": "markdown",
          index: scope.$index,
          content: scope.row.remark,
          onEmitClick: ($event: any) => (_ctx.goMarkdownDialog(scope.row.remark))
        }, null, 8, ["index", "content", "onEmitClick"])
      ]),
      userInfoTableSlot: _withCtx((scope) => [
        _createElementVNode("div", null, _toDisplayString(scope.row.creator_name), 1),
        _createElementVNode("div", null, _toDisplayString(scope.row.created_at), 1)
      ]),
      handleBtnTableSlot: _withCtx((scope) => [
        _createElementVNode("div", null, [
          _createVNode(_component_el_popconfirm, {
            "confirm-button-text": "确定",
            "cancel-button-text": "取消",
            icon: _ctx.InfoFilled,
            "icon-color": "red",
            title: "确认删除该Bug情况",
            onConfirm: ($event: any) => (_ctx.confirmDelete(scope.row))
          }, {
            reference: _withCtx(() => [
              _withDirectives((_openBlock(), _createBlock(_component_el_button, {
                type: "text",
                size: "small"
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode(" 删除 ")
                ])),
                _: 1
              })), [
                [_directive_auth]
              ])
            ]),
            _: 2
          }, 1032, ["icon", "onConfirm"])
        ])
      ]),
      _: 1
    }, 8, ["options"]), [
      [_vShow, !_ctx.isNoBug]
    ]),
    _createVNode(_component_el_dialog, {
      title: _ctx.controlDialog.title,
      "model-value": _ctx.controlDialog.isShow,
      width: "50%",
      center: "",
      "destroy-on-close": "",
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.controlDialog.isShow = false))
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.controlDialog.type), {
          "row-data": _ctx.rowData,
          onClose: _ctx.closeDialog
        }, null, 40, ["row-data", "onClose"]))
      ]),
      _: 1
    }, 8, ["title", "model-value"])
  ]))
}