
import { deleteTaskMember } from '@/api';
import { Message } from '@/common/utils';
import { StatusType } from '@/types';
import { UserInWork } from '@/types/task/member';
import { defineComponent, PropType } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  props: {
    // 当前状态
    status: {
      type: String as PropType<StatusType>,
      required: true,
      default: 'active',
    },
    // 表格数据
    tableData: {
      type: Array as PropType<Array<UserInWork>>,
      required: true,
      default: () => [],
    },
  },
  emits: ['refresh', 'openEdit'],
  setup(_, { emit }) {
    const router = useRouter();
    // 删除任务成员
    const deleteMember = async ({ work_id, id }: UserInWork) => {
      const { code } = await deleteTaskMember(work_id, id);
      if (code === 0) {
        Message('删除成功');
        emit('refresh');
      }
    };

    // 查看跟进
    const goFollow = ({ work_id, id }: UserInWork) => {
      router.push(`/follow?id=${work_id}&uid=${id}`);
    };

    // 新增跟进
    const goCreateFollow = ({ work_id, id }: UserInWork) => {
      router.push(`/create-follow?id=${work_id}&uid=${id}`);
    };

    // 打开编辑的弹窗栏
    const openEdit = (row: UserInWork) => {
      emit('openEdit', row);
    };
    return {
      deleteMember,
      goFollow,
      goCreateFollow,
      openEdit,
    };
  },
});
