import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    data: _ctx.tableData,
    "empty-text": _ctx.message,
    "row-key": "id",
    "tree-props": {children: 'children'},
    border: "",
    style: {"width":"100%"}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, {
        align: "center",
        prop: "complexity",
        label: "复杂度",
        width: "80"
      }),
      _createVNode(_component_el_table_column, {
        align: "center",
        prop: "file",
        label: "文件路径"
      }),
      _createVNode(_component_el_table_column, {
        align: "center",
        prop: "method",
        label: "函数名",
        width: "180"
      }),
      _createVNode(_component_el_table_column, {
        align: "center",
        prop: "nodeType",
        label: "节点类型",
        width: "180"
      }),
      _createVNode(_component_el_table_column, {
        align: "center",
        prop: "line",
        label: "所在行数",
        width: "80"
      }),
      _createVNode(_component_el_table_column, {
        align: "center",
        prop: "column",
        label: "所在列数",
        width: "80"
      }),
      _createVNode(_component_el_table_column, {
        align: "center",
        prop: "message",
        label: "提示信息"
      })
    ]),
    _: 1
  }, 8, ["data", "empty-text"]))
}