import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "page-task-stage" }
const _hoisted_2 = { class: "tab-box" }
const _hoisted_3 = { class: "show-popover__header" }
const _hoisted_4 = { class: "stage-date-picker" }
const _hoisted_5 = { class: "show-popover__footer" }
const _hoisted_6 = { class: "header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_detail_header = _resolveComponent("detail-header")!
  const _component_stage_table = _resolveComponent("stage-table")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_close = _resolveComponent("close")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _directive_auth = _resolveDirective("auth")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_detail_header, {
      rows: _ctx.headerRows,
      onRefresh: _ctx.search
    }, null, 8, ["rows", "onRefresh"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_tabs, {
        modelValue: _ctx.status,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.status) = $event)),
        type: "card",
        onTabChange: _ctx.search
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
            return (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: tab.name,
              label: tab.label,
              name: tab.name
            }, {
              default: _withCtx(() => [
                _createVNode(_component_stage_table, {
                  "table-data": _ctx.tableData,
                  status: _ctx.status,
                  onRefresh: _ctx.search
                }, null, 8, ["table-data", "status", "onRefresh"])
              ]),
              _: 2
            }, 1032, ["label", "name"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue", "onTabChange"]),
      _createVNode(_component_el_popover, {
        visible: _ctx.visible,
        "popper-class": "show-popover",
        "show-arrow": false,
        placement: "bottom-start",
        trigger: "click"
      }, {
        reference: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_el_button, {
              type: "primary",
              plain: "",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.visible = true))
            }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode(" 新增阶段 ")
              ])),
              _: 1
            }),
            _createVNode(_component_el_button, {
              plain: "",
              loading: _ctx.copyLoading,
              onClick: _ctx.handleCopyPlan
            }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode(" 复制排期 ")
              ])),
              _: 1
            }, 8, ["loading", "onClick"])
          ])
        ]),
        default: _withCtx(() => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
            _cache[7] || (_cache[7] = _createElementVNode("div", null, "新增阶段", -1)),
            _createVNode(_component_el_button, {
              type: "text",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.visible = false))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, {
                  size: 15,
                  color: "#000"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_close)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])), [
            [_directive_auth]
          ]),
          _createVNode(_component_el_form, {
            ref: "form",
            inline: "",
            model: _ctx.addForm,
            rules: _ctx.rules
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: "阶段:",
                class: "stage",
                prop: "stage_name"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_autocomplete, {
                    modelValue: _ctx.addForm.stage_name,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.addForm.stage_name) = $event)),
                    class: "inline-input",
                    "select-when-unmatched": "",
                    "trigger-on-focus": "",
                    debounce: 100,
                    "fetch-suggestions": _ctx.queryStage,
                    placeholder: "阶段",
                    onSelect: _ctx.handleStageSelect
                  }, null, 8, ["modelValue", "fetch-suggestions", "onSelect"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "开始:",
                prop: "started_at"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_el_date_picker, {
                      modelValue: _ctx.addForm.started_at,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.addForm.started_at) = $event)),
                      type: "date",
                      placeholder: "开始时间",
                      onChange: _ctx.handleStartPicker
                    }, null, 8, ["modelValue", "onChange"])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "结束:",
                prop: "finished_at",
                class: "stage-end-picker"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_date_picker, {
                    modelValue: _ctx.addForm.finished_at,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.addForm.finished_at) = $event)),
                    type: "date",
                    placeholder: "结束时间"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "负责人:",
                prop: _ctx.addForm.stage_name.indexOf('CodeReview') !== -1 ? 'principal_name' : 'principal_id'
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_autocomplete, {
                    modelValue: _ctx.addForm.principal_name,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.addForm.principal_name) = $event)),
                    class: "inline-input",
                    "select-when-unmatched": "",
                    "trigger-on-focus": "",
                    "fetch-suggestions": _ctx.queryMember,
                    placeholder: "负责人",
                    debounce: 100,
                    onSelect: _ctx.handleMemberSelect
                  }, null, 8, ["modelValue", "fetch-suggestions", "onSelect"])
                ]),
                _: 1
              }, 8, ["prop"])
            ]),
            _: 1
          }, 8, ["model", "rules"]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_el_button, {
              type: "primary",
              size: "small",
              onClick: _ctx.submit
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode(" 确定 ")
              ])),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        _: 1
      }, 8, ["visible"])
    ])
  ]))
}