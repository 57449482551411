import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "component-follow-table" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_mdView = _resolveComponent("mdView")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_auth = _resolveDirective("auth")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_table, {
      data: _ctx.tableData,
      border: "",
      size: "medium",
      class: "table",
      "row-class-name": "table__column",
      "row-key": "id"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "id",
          label: "id",
          width: "80"
        }),
        _createVNode(_component_el_table_column, {
          prop: "title",
          label: "标题",
          "min-width": "200"
        }),
        _createVNode(_component_el_table_column, {
          prop: "remark",
          label: "跟进内容",
          "min-width": "400"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_mdView, {
              index: scope.row.id,
              content: scope.row.remark
            }, null, 8, ["index", "content"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "score",
          label: "评分",
          width: "70"
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", {
              class: "can-pointer",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setHiddenStatus(!_ctx.hiddenStatus)))
            }, [
              _cache[1] || (_cache[1] = _createTextVNode(" 评分")),
              _createVNode(_component_svg_icon, {
                "icon-class": _ctx.hiddenStatus? 'eye-hide': 'eye-show'
              }, null, 8, ["icon-class"])
            ])
          ]),
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(_ctx.hiddenStatus ? '*' : scope.row.score), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "address",
          label: "关联人员",
          width: "110"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", null, _toDisplayString(scope.row.user_name || '--'), 1),
            _createElementVNode("div", null, _toDisplayString(scope.row.created_at ? _ctx.dayjs(scope.row.created_at).format('YYYY-MM-DD') : '--'), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "stage_name",
          label: "关联阶段",
          width: "110"
        }),
        _createVNode(_component_el_table_column, {
          label: "操作",
          width: "80"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", null, [
              _createVNode(_component_el_popover, {
                placement: "left",
                width: 200,
                trigger: "click",
                "auto-close": 1500
              }, {
                reference: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "text",
                    size: "small"
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" 查看 ")
                    ])),
                    _: 1
                  })
                ]),
                default: _withCtx(() => [
                  (scope?.row)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createElementVNode("div", null, "创建人： " + _toDisplayString(scope.row.creator_name), 1),
                        (scope.row.deletor_name)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_3, " 删除人： " + _toDisplayString(scope.row.deletor_name), 1))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024)
            ]),
            _createElementVNode("div", null, [
              (_ctx.status === 'active')
                ? (_openBlock(), _createBlock(_component_el_popconfirm, {
                    key: 0,
                    "confirm-button-text": "确定",
                    "cancel-button-text": "取消",
                    icon: _ctx.InfoFilled,
                    "icon-color": "red",
                    title: "确认删除该跟进",
                    onConfirm: ($event: any) => (_ctx.confirmDelete(scope.row))
                  }, {
                    reference: _withCtx(() => [
                      _withDirectives((_openBlock(), _createBlock(_component_el_button, {
                        type: "text",
                        size: "small"
                      }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode(" 删除 ")
                        ])),
                        _: 1
                      })), [
                        [_directive_auth]
                      ])
                    ]),
                    _: 2
                  }, 1032, ["icon", "onConfirm"]))
                : _createCommentVNode("", true)
            ]),
            _withDirectives((_openBlock(), _createElementBlock("div", null, [
              (_ctx.status === 'active')
                ? (_openBlock(), _createBlock(_component_el_button, {
                    key: 0,
                    type: "text",
                    size: "small",
                    onClick: ($event: any) => (_ctx.continueFollow(scope.row))
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode(" 继续跟进 ")
                    ])),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true)
            ])), [
              [_directive_auth]
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"])
  ]))
}