
import {
  defineComponent, getCurrentInstance, reactive, ref, watch,
} from 'vue';
import MdEditor from '@/components/Markdown/MdEditor.vue';
import { useRoute, useRouter } from 'vue-router';
import {
  createFollowUp, getUser, getUserStage,
} from '@/api';
import { HeaderRow } from '@/types/task/header';
import { FollowData, FollowForm } from '@/types/task/followup';
import { Message } from '@/common/utils';
import { useHeaderRows } from '@/hooks/useHeaderRows';
import { useFormRequired } from '@/hooks/useFormRequired';
import { useKpiList } from '@/hooks/useKpiList';
import DetailHeader from './components/detail-header.vue';

export default defineComponent({
  components: {
    MdEditor,
    DetailHeader,
  },
  setup () {
    const headerRows = ref<Array<HeaderRow>>([]); // 页头迭代信息
    const [route, router] = [useRoute(), useRouter()];
    /*
     * 自动填充所需的信息 从路由上取
     * id：迭代id
     * uid：用户id
     * sid：阶段id
     * type: 跟进类型 默认为任务迭代跟进，其他则为非任务迭代 - formUser 人员列表跳转
     */
    const {
      id = '', uid = '', sid = '', type = '',
    } = route.query;
    const canSetUserFormData = ref(true);
    // 表单内容
    const formData = ref<FollowForm>({
      user_id: null,
      stage_id: null,
      kpi_id: null,
      score: null,
      title: '',
      remark: '',
      user_origin_id: !id && uid ? +uid : null,
      work_id: id ? +id : null,
    });
    const isManager = ref(false);
    const curUser = ref<any>(null);

    // 绩效类型映射评分区间
    const scoreMap = {
      avg: {
        placeholder: '支持录入区间0至10，整数或小数点后一位',
        regExp: /^([0-9](\.[0-9])?|10(\.0)?)$/,
      },
      sum: {
        placeholder: '支持录入区间-30至+30，整数或小数点后一位',
        regExp: /^(-?([0-2]?\d(\.\d)?|30(\.0)?))$/,
      },
    };

    // 用户 & 阶段 & 评分下拉框选项
    const options = reactive<Omit<FollowData, 'work'>>({
      users: [],
      stages: [],
      scoreConfig: scoreMap.avg,
    });
    const isRequired = useFormRequired({
      title: '标题',
      remark: '正文',
    });
    // 表单规则
    const formDataRules = {
      ...isRequired,
      score: {
        type: 'number',
        required: true,
        validator: (rule: any, value: any) => {
          if (!options.scoreConfig?.regExp.test(`${value}`)) {
            return new Error('请输入正确的数值')
          }
          return true
        },
      }
    };
    // 搜索当前迭代相关信息
    const search = async () => {
      if (!id) return;
      const { code, data } = await getUserStage(id);
      if (code === 0) {
        headerRows.value = useHeaderRows(data.work, []);
        options.users = data.users;
        options.stages = data.stages;
      }
    };

    // 考核相关表单
    const { kpiList, remoteKpiList } = useKpiList();
    // 更新考核选择项
    const updateKpiList = (userId: number | null) => {
      kpiList.value = [];
      formData.value.kpi_id = null;
      const target = options.users.find(el => el.id === userId);
      if (!target) return;
      curUser.value = target;
      isManager.value = target.is_manager || false;
      remoteKpiList({
        user_type: target.type,
        is_manager: target.is_manager,
      });
    };
    watch(() => formData.value.user_id, (nVal) => {
      if (!nVal) return;
      updateKpiList(nVal);
    });
    // 当考核项已选择时，标题为考核项的备注
    const kpiChange = (e: number) => {
      const selectKpi = kpiList.value.find(el => el.id === e);
      if (selectKpi) {
        formData.value.score = null;
        formData.value.title = selectKpi.name;
        options.scoreConfig = scoreMap[selectKpi.score_type];
      }
    };

    // 表单校验相关
    const ctx = getCurrentInstance()?.proxy;
    // 创建跟进
    const createFollow = async () => {
      const form = { ...formData.value };
      const optional = {} as Partial<FollowForm>;
      Object.keys(form).forEach(key => {
        const val = Reflect.get(form, key);
        if (val) {
          Reflect.set(optional, key, val);
        }
      });
      const { code } = await createFollowUp({
        ...optional,
      });
      if (code === 0) {
        Message('创建成功');
        (ctx?.$refs.form as any).resetFields();
        const { user_id = '', stage_id = '' } = optional;
        if (type === 'fromUser') {
          router.push({ name: 'PersonnelList' });
        } else {
          router.push({
            name: 'Follow',
            query: {
              id,
              uid: user_id,
              sid: stage_id,
            },
          });
        }
      }
    };

    // 表单提交校验
    const handleSubmit = () => {
      (ctx?.$refs.form as any).validate((valid: any) => {
        valid && createFollow();
      });
    };

    /**
     * 初始化表单，适应不同入口的数据处理
     */
    const initForm = async () => {
      // 如果没有type则代表是默认入口，此处通过search查询用户迭代信息
      !type && await search();
      // 此处属于人员迭代，无需包含任务
      // tapd: https://www.tapd.cn/21753541/prong/stories/view/1121753541001009045?url_cache_key=fd4777f451577e5971a56141ab492c5e&action_entry_type=stories
      if (type === 'fromUser' && uid) {
        canSetUserFormData.value = false;
        const { code, data } = await getUser({
          id: +uid,
          status: 'active',
        });
        if (code === 0) {
          options.users = data.users.map(el => {
            el.type = el.type_code;
            return el;
          });
        }
      }
      uid && (formData.value.user_id = +uid);
      sid && (formData.value.stage_id = +sid);
    };
    initForm();

    /**
     * 是否考核项筛选
     */
    const handleSwitchChange = () => {
      if (!curUser.value) return;
      kpiList.value = [];
      formData.value.kpi_id = null;
      remoteKpiList({
        user_type: curUser.value.type,
        is_manager: isManager.value,
      });
    };

    return {
      headerRows,
      formData,
      options,
      formDataRules,
      handleSubmit,
      updateKpiList,
      kpiChange,
      kpiList,
      canSetUserFormData,
      handleSwitchChange,
      isManager,
      curUser
    };
  },
});
