
import { Work } from '@/types/task/work';
import { defineComponent, PropType } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

interface UserInfo {
  id: number;
  name: string;
  type: 'currentWork' | 'personnel';
}

export default defineComponent({
  props: {
    // 表格数据
    tableData: {
      type: Array as PropType<Array<Work>>,
      required: true,
      default: () => [],
    },
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const setUser = (payload: Partial<UserInfo>) => store.commit('personnel/setUserDetail', payload);

    // 设置跳转用户列表指定用户
    const goPersonnel = (id: number) => {
      setUser({ id, type: 'currentWork' });
      router.push('/personnel');
    };

    // 设置跳转指定用户任务列表
    const goTask = (id: number) => {
      store.commit('personnel/setWorkId', id);
      router.push('/');
    }; // 跳转具体的tapd / 自定义URL

    return {
      goTask,
      goPersonnel,
    };
  },
});
