import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createBlock(_component_el_pagination, {
    "current-page": _ctx.formInline.page,
    "page-sizes": [10, 20, 30, 40],
    "page-size": _ctx.formInline.count,
    layout: "total, prev, pager, next, jumper",
    total: _ctx.total,
    onCurrentChange: _ctx.handleCurrentChange
  }, null, 8, ["current-page", "page-size", "total", "onCurrentChange"]))
}