import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdView = _resolveComponent("mdView")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_mdView, {
      index: _ctx.rowData.id,
      content: _ctx.rowData.content
    }, null, 8, ["index", "content"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_button, { onClick: _ctx.closeDialog }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(" 关闭 ")
        ])),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}