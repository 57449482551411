
import {
  defineComponent, PropType,
} from 'vue';
import { ComplexityColumn, ComplexityGroupColumn } from '@/types/complexity/complexity';

export default defineComponent({
  props: {
    tableData: {
      type: Array as PropType<Array<ComplexityColumn | ComplexityGroupColumn>>,
      required: true,
      default: () => [],
    },
    // 无数据时提示的消息 这里为了兼容项目还没迁入复杂度的脚本
    message: {
      type: String,
      required: true,
      default: () => '暂无数据',
    },
  },
});
