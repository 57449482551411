
// markdown所需的css样式
import '@toast-ui/editor/dist/toastui-editor.css';
import Viewer from '@toast-ui/editor/dist/toastui-editor-viewer';
import { defineComponent, onMounted, watch } from 'vue';

export default defineComponent({
  name: 'MdView',
  props: {
    content: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    let viewer: Viewer | null = null;

    onMounted(() => {
      viewer = new Viewer({
        el: document.querySelector(`#lxMdView${props.index}`) as HTMLElement,
        initialValue: props.content,
      });
    });

    watch(
      () => props.content,
      (newVal) => {
        viewer?.setMarkdown(newVal);
      },
    )
  },
});
