
import { computed, defineComponent, ref } from 'vue';
import lxCrud from '@/components/Crud/index.vue';
import { deleteFollowUp } from '@/api';
import { Message } from '@/common/utils';
import { FollowUpRow } from '@/types/task/followup';
import dayjs from 'dayjs';
import { useRolesList } from '@/hooks/useRolesList';
import { useKpiList } from '@/hooks/useKpiList';
import { useStore } from 'vuex';
import mdView from '@/components/Markdown/MdView.vue';
import { useCrud } from './crud.opt';

export default defineComponent({
  components: {
    lxCrud,
    mdView,
  },
  setup() {
    const store = useStore();
    // Crud 组件实例
    const crud = ref();

    // Crud 配置文件
    const { options } = useCrud();

    // 考核项
    const { kpiList, remoteKpiList } = useKpiList();
    // 当角色更新时需要清空考核项并更新考核列表
    const roleChange = (user_type: string | null, formInLine: any) => {
      kpiList.value = [];
      formInLine.props.kpi_id = null;
      if (!user_type) return;
      remoteKpiList({ user_type });
    };

    // 获取角色列表
    const { roleList, remoteRolesList } = useRolesList();
    // 拉取角色列表
    remoteRolesList();

    // 处理评分隐藏
    const hiddenStatus = computed(() => store.getters['setting/getScoreHiddenStatus']);
    const setHiddenStatus = () => {
      store.commit('setting/switchScoreHideStatus');
    };

    // 确认删除操作
    const confirmDelete = async ({ id }: FollowUpRow) => {
      const { code } = await deleteFollowUp(0, id);
      if (code === 0) {
        Message('删除成功');
        // 触发crud组件的刷新功能
        crud.value.refresh();
      } else {
        Message('删除失败', 'error');
      }
    };

    return {
      crud,
      options,
      roleList,
      kpiList,
      confirmDelete,
      roleChange,
      dayjs,
      hiddenStatus,
      setHiddenStatus,
    };
  },
});
