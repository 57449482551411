import { ref } from 'vue';
import { KpiList } from '@/types/personnel/personnel';
import { getUserKpiList } from '@/api';

const kpiList = ref<Array<KpiList>>([]);

type IParam = {
  user_type: string
  is_manager?: boolean | null
}

export const useKpiList = () => {
  const remoteKpiList = async(params: Partial<IParam>) => {
    if (!params) return;
    const { data } = await getUserKpiList(params);
    kpiList.value = [...data.data];
  };
  return {
    kpiList,
    remoteKpiList,
  };
};
