
import {
  getTaskMember, getUser, updateTaskMember, updateUserCostOrDifficult,
} from '@/api';
import { Member, UserInWork } from '@/types/task/member';
import {
  defineComponent, getCurrentInstance, onMounted, reactive, ref, watch,
} from 'vue';
import { useRoute } from 'vue-router';
import { Message } from '@/common/utils';
import { HeaderRow } from '@/types/task/header';
import { useHeaderRows } from '@/hooks/useHeaderRows';
import { useFormRequired } from '@/hooks/useFormRequired';
import DetailHeader from './components/detail-header.vue';
import MemberTable from './components/member-table.vue';

export default defineComponent({
  components: {
    DetailHeader,
    MemberTable,
  },
  setup() {
    const headerRows = ref<Array<HeaderRow>>([]); // 页头迭代信息
    const memberArr = ref<Array<Member & { value: string }>>([]); // 模糊搜索用户数组
    // 过滤是否从下拉选择中获取用户信息
    const filterUser = (rule: any, value: string, callback: (...args: any) => any) => {
      const index = memberArr.value.findIndex(member => member.value === value);
      if (value === '') {
        callback(new Error('请添加人员'));
      } else if (index === -1) {
        callback(new Error('请从下拉选择中添加人员'));
      } else {
        callback();
      }
    };
    // form表单规则
    const rules = reactive({
      name: [
        { validator: filterUser, required: true },
      ],
    });
    const route = useRoute();
    const workId = route.query.id || ''; // 迭代id
    const formInline = reactive({
      name: '',
      mobile: '',
      status: 'active',
    }); // 搜索成员表单字段
    const addForm = reactive({
      id: null as number | null,
      name: '',
      is_code_reviewer: false,
    }); // 新增成员表单字段
    const tableData = ref<Array<UserInWork>>([]);
    const tabs = [
      { label: '正常', name: 'active' },
      { label: '已删除', name: 'deleted' },
    ]; // 渲染tabs数组

    // 搜索成员列表
    const search = async () => {
      const { code, data } = await getTaskMember(workId, formInline);
      if (code === 0) {
        tableData.value = data.users;
        headerRows.value = useHeaderRows(data.work, ['follow', 'stage', 'create', 'taskBug']);
      }
    };

    const queryParams = reactive({
      page: 1,
      count: 10,
    }); // 模糊搜索字段

    // 获取模糊匹配用户信息
    const queryMember = async (name: string, cb: (...args: any) => any) => {
      const { code, data } = await getUser({ ...queryParams, name, status: 'active' });
      if (code === 0) {
        // 缓存起来 用于表单校验
        memberArr.value = data.users.map(member => ({
          ...member,
          value: member.name,
          status: 'active',
        }));
        cb(memberArr.value);
      } else {
        Message('获取用户失败', 'error');
      }
    };

    const handleMemberSelect = (item: Member) => {
      // 在没有相关模糊搜索下 将id置为null
      addForm.id = item.id || null;
    };

    const visible = ref(false); // 新增人员弹框
    // 新增人员
    const createMember = async () => {
      const payload = { user_id: addForm.id };
      if (addForm.is_code_reviewer) Reflect.set(payload, 'is_code_reviewer', addForm.is_code_reviewer);
      const { code } = await updateTaskMember(workId, payload);
      if (code === 0) {
        Message('新增成功');
        visible.value = false;
        await search();
      }
    };
    const ctx = getCurrentInstance()?.proxy;
    // form表单校验
    const addMember = () => {
      (ctx?.$refs.form as any).validate((valid: any) => {
        if (valid) {
          createMember();
        } else {
          return false;
        }
      });
    };

    // 关闭弹出框时清空表单
    watch(visible, (val) => {
      if (!val) {
        (ctx?.$refs.form as any).resetFields();
        addForm.is_code_reviewer = false;
      }
    });

    /**
     * 编辑工作量或难度 相关弹窗及表单
     */
    const controlDialog = reactive({
      title: '',
      isShow: false,
      type: '',
    });
    const editPropsMap: {[key: string]: string} = {
      cost_day: '工作量',
      degree_of_difficult: '难度系数',
    };
    const editFormRules = useFormRequired(editPropsMap);
    const editFormBody = reactive<{[key: string]: any }>({ cost_day: undefined, degree_of_difficult: undefined });
    let editRow: UserInWork;
    // 接受table的emit回参
    const openDialogForEdit = (row: any) => {
      editRow = row;
      const { cost_day, degree_of_difficult } = editRow;
      editFormBody.cost_day = cost_day || undefined;
      editFormBody.degree_of_difficult = (degree_of_difficult / 10) || 3;
      controlDialog.isShow = true;
      controlDialog.title = '修改难度、工作量';
    };
    // 更新任务参与人员工作量和难度系数
    const updateCostOrDifficult = async () => {
      const { cost_day, degree_of_difficult } = editFormBody;
      const { code } = await updateUserCostOrDifficult(editRow.work_id, editRow.id, { cost_day, degree_of_difficult: (degree_of_difficult * 10) });
      if (code === 0) {
        Message('设置成功');
        controlDialog.isShow = false;
        search();
      }
    };
    // 提交更改前的表单校验
    const submitEditForm = async () => {
      (ctx?.$refs.editForm as any).validate((valid: any) => {
        if (valid) {
          updateCostOrDifficult();
        } else {
          return false;
        }
      });
    };
    // 关闭前的回调 - 用于重置校验结果
    const beforeCloseDialog = (done: any) => {
      (ctx?.$refs.editForm as any).resetFields();
      done();
    };

    onMounted(() => {
      search();
    });

    return {
      tabs,
      addForm,
      visible,
      queryMember,
      tableData,
      addMember,
      formInline,
      headerRows,
      search,
      rules,
      handleMemberSelect,
      openDialogForEdit,
      controlDialog,
      editFormBody,
      editFormRules,
      submitEditForm,
      beforeCloseDialog,
    };
  },
});
