import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "component-list-table" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_auth = _resolveDirective("auth")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_table, {
      data: _ctx.tableData,
      border: "",
      size: "medium",
      class: "table",
      "row-class-name": "table__column"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "id",
          label: "id",
          align: "center"
        }),
        _createVNode(_component_el_table_column, {
          label: "头像",
          align: "center"
        }, {
          default: _withCtx((scope) => [
            (scope.row.avatar)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  alt: "头像",
                  class: "avatar",
                  src: scope.row.avatar
                }, null, 8, _hoisted_2))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, " -- "))
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "name",
          label: "姓名",
          align: "center"
        }),
        _createVNode(_component_el_table_column, {
          prop: "mobile_number",
          label: "手机",
          align: "center"
        }),
        _createVNode(_component_el_table_column, {
          label: "邮箱",
          align: "center",
          "min-width": "200"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.email || '--'), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "角色",
          prop: "type",
          align: "center"
        }),
        _createVNode(_component_el_table_column, {
          label: "操作",
          width: "80",
          align: "center"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", null, [
              _createVNode(_component_el_button, {
                type: "text",
                size: "small",
                onClick: ($event: any) => (_ctx.goTask(scope.row))
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode(" 查看任务 ")
                ])),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _withDirectives((_openBlock(), _createElementBlock("div", null, [
              _createVNode(_component_el_button, {
                type: "text",
                size: "small",
                onClick: ($event: any) => (_ctx.goCreateFollow(scope.row))
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode(" 新增跟进 ")
                ])),
                _: 2
              }, 1032, ["onClick"])
            ])), [
              [_directive_auth]
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"])
  ]))
}