
import {
  defineComponent, onMounted, ref, watch,
} from 'vue';
import { getComplexity } from '@/api';
import Menus from '@/components/LeftNav/menus.vue';
import { useRoute } from 'vue-router';
import {
  Complexity, ComplexityColumn, ComplexityData, ComplexityGroup, ComplexityGroupColumn, ProjectInfo,
} from '@/types/complexity/complexity';
import groupBy from 'lodash/groupBy';
import { v4 as uuid } from 'uuid';
import ComplexityTable from './components/table.vue';

export default defineComponent({
  components: {
    Menus,
    ComplexityTable,
  },
  setup() {
    const route = useRoute();
    const message = ref<string>('Loading...');
    const name = ref<string>(route.query.name as string);
    const tableData = ref<Array<ComplexityColumn | ComplexityGroupColumn>>([]);
    // 缓存两种tab下的数组 避免重新获取文件
    const [sortByTable, groupByTable] = [
      ref<ComplexityColumn[]>([]),
      ref<ComplexityGroupColumn[]>([]),
    ];
    const info = ref<ProjectInfo>({
      files: 0,
      functions: 0,
      average: '',
    });
    const renderRule = ref<string>('sortBy');
    const tabs = [
      { label: '按复杂度排序', name: 'sortBy' },
      { label: '按文件分类', name: 'groupBy' },
    ]; // 渲染tabs数组

    const initValues = (): void => {
      message.value = 'Loading...';
      info.value = {
        files: 0,
        functions: 0,
        average: '',
      };
    };

    // 计算项目相关信息
    const projectInfo = (data: ComplexityData): void => {
      const tableGroup: ComplexityGroup = groupBy(data, 'file');
      const sumAverage = (total: number, item: Complexity) => total + item.messages.complexity;
      const average = (data.reduce(sumAverage, 0) / data.length).toFixed(2);
      info.value = {
        average,
        functions: data.length,
        files: Object.keys(tableGroup).length,
      };
    };

    // 切换tab栏
    const switchTab = (): void => {
      tableData.value = renderRule.value === 'sortBy'
        ? sortByTable.value
        : groupByTable.value;
    };

    // 按照文件路径 分类汇总复杂度信息
    const groupByTableData = (data: ComplexityData) => {
      const tableGroup: ComplexityGroup = groupBy(data, 'file');
      return Object.keys(tableGroup)
        .map(file => {
          const fileSplit = file.split('/');
          const children = tableGroup[file].map(item => ({
            ...item.messages,
            file: fileSplit[fileSplit.length - 1],
            id: `cid${uuid()}`,
          }));
          return {
            file,
            children,
            id: `pid${uuid()}`,
          };
        });
    };

    const fetchComplexity = async () => {
      try {
        initValues();
        const { data } = await getComplexity(name.value);
        sortByTable.value = data.map(item => ({
          ...item.messages,
          file: item.file,
        }));
        groupByTable.value = groupByTableData(data);
        switchTab();
        projectInfo(data);
      } catch (e: any) {
        const {
          response: {
            status = 500,
          } = {},
        } = e || {};
        sortByTable.value = [];
        groupByTable.value = [];
        const errorMsg = `code ${status} 获取${name.value ? `${name.value}.json` : '路由参数'}失败`;
        message.value = errorMsg;
        throw new Error(errorMsg);
      }
    };

    onMounted(() => {
      fetchComplexity();
    });

    watch(
      () => route.query.name,
      newValue => {
        tableData.value = [];
        name.value = newValue as string;
        fetchComplexity();
      },
    );

    return {
      message,
      name,
      tableData,
      renderRule,
      tabs,
      info,
      fetchComplexity,
      switchTab,
    };
  },
});
