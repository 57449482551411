
import {
  computed,
  defineComponent, reactive, ref,
} from 'vue';
import lxCrud from '@/components/Crud/index.vue';
import {
  deleteWorkBug, finishWork,
} from '@/api';
import { Message } from '@/common/utils';
import Ellipsis from '@/components/Ellipsis/index.vue';
import DetailHeader from '@/modules/task/components/detail-header.vue';
import parseMarkdown from '@/modules/task/taskBug/components/parseMarkdown.vue';
import { useCrud } from '@/modules/task/taskBug/crud.opt';
import { TaskBugListData } from './type';
import taskBugForm from './components/taskBugForm.vue';

export default defineComponent({
  name: 'TaskBug',
  components: {
    lxCrud,
    Ellipsis,
    DetailHeader,
    taskBugForm,
    parseMarkdown,
  },
  setup() {
    // 获取crud配置详情
    const { options, headerRows, work } = useCrud();
    const crud = ref();

    const switchLoading = ref(false);

    const isNoBug = computed(() => work.value && work.value.is_no_bug);

    // 有无bug选项切换
    const handleSwitchChange = async () => {
      if (work.value && !switchLoading.value) {
        try {
          switchLoading.value = true;
          const { code } = await finishWork(work.value.id, {
            is_no_bug: work.value.is_no_bug,
          });
          if (code === 0) {
            Message('修改成功');
            // 触发crud组件的刷新功能
            crud.value.refresh();
          } else {
            work.value = {
              ...work.value,
              is_no_bug: !work.value.is_no_bug,
            };
            Message('修改失败', 'error');
          }
        } catch (err) {
          work.value = {
            ...work.value,
            is_no_bug: !work.value.is_no_bug,
          };
          Message('修改失败', 'error');
          throw err;
        } finally {
          switchLoading.value = false;
        }
      }
    };

    // 确认删除bug详情
    const confirmDelete = async ({ id: bug_id, work_user_id }: TaskBugListData) => {
      const { code } = await deleteWorkBug(work_user_id, bug_id);
      if (code === 0) {
        Message('删除成功');
        // 触发crud组件的刷新功能
        crud.value.refresh();
      } else {
        Message('删除失败', 'error');
      }
    };

    /**
     * 弹窗模块
     */
    const controlDialog = reactive({ title: '', isShow: false, type: 'taskBugForm' });
    const rowData = ref({});
    const closeDialog = (needRefresh = false) => {
      controlDialog.isShow = false;
      needRefresh && crud.value.refresh();
    };

    /**
     * markdown备注模块
     */
    const goMarkdownDialog = (content: string) => {
      controlDialog.type = 'parseMarkdown';
      controlDialog.title = 'Bug备注';
      controlDialog.isShow = true;
      rowData.value = {
        content,
      };
    };
    /**
     * 表单模块
     */
    // 打开新增表单
    const goCreate = () => {
      controlDialog.isShow = true;
      controlDialog.title = 'BUG录入页';
      controlDialog.type = 'taskBugForm';
    };

    const handleRefresh = () => {
      options.fetch()
    }

    return {
      options,
      confirmDelete,
      crud,
      controlDialog,
      goCreate,
      goMarkdownDialog,
      headerRows,
      closeDialog,
      rowData,
      switchLoading,
      work,
      handleSwitchChange,
      isNoBug,
      handleRefresh
    };
  },
});
