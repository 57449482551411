import { ref } from 'vue';
import { CrudOptions } from '@/components/Crud/crud.type';
import {
  BugLevelEnum,
  BugStageEnum,
  TestStageTaskTypeEnum as typeList,
  TestStageUserTypeEnum as roleList,
} from '@/common/enum';
import dayUtils from '@/common/dayUtils';
import { getUser, getWorkUserBugOverviews } from '@/api';
import { convertFloatInInt, getEnumArrForKey, Message } from '@/common/utils';

// 列表获取
const fetchList = async (params: any) => {
  const reqData = { ...params };
  delete reqData.finished_times;
  const { code, data } = await getWorkUserBugOverviews(reqData);
  if (code !== 0) {
    Message('获取失败', 'error');
    return;
  }
  return {
    tableList: data.work_bug_overviews.map((el) => {
      if (el.work_user_bugs) {
        el.work_user_bugs = el.work_user_bugs.map(subEl => {
          subEl.levelLabel = getEnumArrForKey(BugLevelEnum, 'value', subEl.level)?.label ?? '';
          subEl.stageLabel = getEnumArrForKey(BugStageEnum, 'value', subEl.stage)?.label ?? '';
          subEl.convertCount = convertFloatInInt(subEl.count);
          return subEl;
        });
      }
      return el;
    }),
    totalNum: data.total_count,
  };
};

// 获取用户列表
const queryMember = async (query: string | number) => {
  const { code, data } = await getUser({
    page: 1,
    count: 10,
    name: query,
    status: 'active',
  });
  if (code === 0) {
    return data.users.map(el => ({
      value: el.id,
      label: el.name,
    }));
  }
  Message('获取用户失败', 'error');
};

// 获取当前季度至今的默认时间
const default_finished_start_at = dayUtils.startOf('week').add(1, 'day').format('YYYY-MM-DD HH:mm:ss');
const default_finished_end_at = dayUtils.format('YYYY-MM-DD HH:mm:ss');

export const useCrud = () => {
  const options = ref<CrudOptions>(
    {
      title: '提测bug情况',
      fetch: fetchList,
      column: [
        {
          label: '任务名称',
          prop: 'work_name',
          type: 'input',
          search: true,
          hidden: true,
        },
        {
          label: '人员',
          prop: 'user_id',
          type: 'select',
          search: true,
          hidden: true,
          remoteMethod: queryMember,
        },
        {
          label: '角色',
          prop: 'user_type',
          type: 'select',
          search: true,
          hidden: true,
          enum: roleList,
        },
        {
          label: '任务类型',
          prop: 'work_type',
          type: 'select',
          search: true,
          hidden: true,
          enum: typeList,
        },
        {
          label: '迭代完成时间',
          prop: 'finished_times',
          type: 'date',
          search: true,
          hidden: true,
          defaultValue: [
            default_finished_start_at,
            default_finished_end_at,
          ],
          searchKey: [
            'finished_start_at',
            'finished_end_at',
          ],
        },
        {
          label: '人员名称',
          prop: 'user_name',
          width: 80,
        },
        {
          label: '任务名称',
          prop: 'work_name',
          slot: true,
        },
        {
          label: '任务类型',
          prop: 'work_type',
          width: 100,
        },
        {
          label: 'bug概览',
          prop: 'work_user_bugs',
          slot: true,
        },
        {
          label: '工作量',
          prop: 'cost_day',
          width: 70,
        },
        {
          label: '难度',
          prop: 'degree_of_difficult',
          width: 80,
          slot: true,
        },
        {
          label: 'bug_score',
          prop: 'bug_score',
          slot: true,
          slotHeader: true,
          width: 120,
        },

      ],
    },
  );

  return {
    options,
  };
};
