import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_editor = _resolveComponent("md-editor")!

  return (_openBlock(), _createBlock(_component_md_editor, {
    ref: "markdown",
    modelValue: _ctx.content,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.content) = $event)),
    class: "page-personnel-editor"
  }, null, 8, ["modelValue"]))
}