
import {
  defineComponent, onMounted, ref,
} from 'vue';
import MdEditor from '@/components/Markdown/MdEditor.vue';
import { exportUserWork } from '@/api';

export default defineComponent({
  components: {
    MdEditor,
  },
  setup() {
    const markdown = ref(); // markdown实例
    const content = ref(); // markdown正文

    // 初始化markdown信息
    const init = async () => {
      const params = localStorage.getItem('exportContent');
      if (params) {
        const { code, data } = await exportUserWork(JSON.parse(params));
        if (code === 0) {
          content.value = data;
          markdown.value.setMarkdown(data);
        }
      }
    };

    onMounted(() => {
      init();
    });

    return {
      markdown,
      content,
    };
  },
});
