
import { defineComponent, PropType } from 'vue';
import mdView from '@/components/Markdown/MdView.vue';

export default defineComponent({
  name: 'ParseMarkdown',
  components: {
    mdView,
  },
  props: {
    rowData: {
      type: Object as PropType<{content: string}>,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, ctx) {
    // 关闭弹窗
    const closeDialog = () => {
      ctx.emit('close');
    };
    return {
      closeDialog,
    };
  },
});
