import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "page-create-container" }
const _hoisted_2 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_md_editor = _resolveComponent("md-editor")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "title" }, " 新建工作任务 ", -1)),
    _createVNode(_component_el_tabs, {
      modelValue: _ctx.activeName,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.activeName) = $event)),
      onTabChange: _ctx.clearForm
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
          return (_openBlock(), _createBlock(_component_el_tab_pane, {
            key: tab.name,
            label: tab.label,
            name: tab.name
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form, {
                ref_for: true,
                ref: 'submitForm' + tab.name,
                model: _ctx.formData,
                rules: _ctx.formDataRules,
                "label-width": "120px",
                class: "annoucement__form"
              }, {
                default: _withCtx(() => [
                  (tab.name !== 'custom')
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 0,
                        label: "迭代名称：",
                        prop: "title"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_autocomplete, {
                            modelValue: _ctx.formData.title,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.title) = $event)),
                            class: "inline-input",
                            "trigger-on-focus": "",
                            "fetch-suggestions": _ctx.querySearchAsync,
                            debounce: 100,
                            placeholder: "请输入迭代名称",
                            onSelect: _ctx.handleSelect
                          }, null, 8, ["modelValue", "fetch-suggestions", "onSelect"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_el_form_item, {
                    label: "任务名称：",
                    prop: "name"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: _ctx.formData.name,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.name) = $event)),
                        type: "text",
                        placeholder: "请输入任务名称",
                        maxlength: "100"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  (tab.name === 'custom')
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 1,
                        label: "相关URL：",
                        prop: "url"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            modelValue: _ctx.formData.url,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.url) = $event)),
                            type: "text",
                            placeholder: "请输入相关URL",
                            maxlength: "256"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_el_form_item, {
                    label: "正文：",
                    prop: "remark"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_md_editor, {
                        ref_for: true,
                        ref: `${tab.name}Markdown`,
                        modelValue: _ctx.formData.remark,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.remark) = $event)),
                        style: {"width":"1000px"}
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["model", "rules"]),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_el_button, {
                  plain: "",
                  onClick: ($event: any) => (_ctx.submit('submitForm' + tab.name))
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" 提交 ")
                  ])),
                  _: 2
                }, 1032, ["onClick"])
              ])
            ]),
            _: 2
          }, 1032, ["label", "name"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "onTabChange"])
  ]))
}