import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "page-task-member" }
const _hoisted_2 = { class: "tab-box" }
const _hoisted_3 = { class: "show-popover__header" }
const _hoisted_4 = { class: "show-popover__footer" }
const _hoisted_5 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_detail_header = _resolveComponent("detail-header")!
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_member_table = _resolveComponent("member-table")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_close = _resolveComponent("close")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_detail_header, {
      rows: _ctx.headerRows,
      onRefresh: _ctx.search
    }, null, 8, ["rows", "onRefresh"]),
    _createVNode(_component_el_form, {
      inline: "",
      model: _ctx.formInline,
      class: "form-inline"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: "姓名:",
          prop: "name"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_autocomplete, {
              modelValue: _ctx.formInline.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formInline.name) = $event)),
              class: "inline-input",
              "select-when-unmatched": "",
              "trigger-on-focus": "",
              debounce: 100,
              "fetch-suggestions": _ctx.queryMember,
              placeholder: "姓名",
              onSelect: _ctx.handleMemberSelect
            }, null, 8, ["modelValue", "fetch-suggestions", "onSelect"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "电话:" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.formInline.mobile,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formInline.mobile) = $event)),
              placeholder: "电话"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _ctx.search
            }, {
              default: _withCtx(() => _cache[13] || (_cache[13] = [
                _createTextVNode(" 查询 ")
              ])),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_tabs, {
        modelValue: _ctx.formInline.status,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formInline.status) = $event)),
        type: "card",
        onTabChange: _ctx.search
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
            return (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: tab.name,
              label: tab.label,
              name: tab.name
            }, {
              default: _withCtx(() => [
                _createVNode(_component_member_table, {
                  "table-data": _ctx.tableData,
                  status: _ctx.formInline.status,
                  onOpenEdit: _ctx.openDialogForEdit,
                  onRefresh: _ctx.search
                }, null, 8, ["table-data", "status", "onOpenEdit", "onRefresh"])
              ]),
              _: 2
            }, 1032, ["label", "name"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue", "onTabChange"]),
      _createVNode(_component_el_popover, {
        visible: _ctx.visible,
        "popper-class": "show-popover",
        "show-arrow": false,
        placement: "bottom-start"
      }, {
        reference: _withCtx(() => [
          _createVNode(_component_el_button, {
            type: "primary",
            class: "header",
            plain: "",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.visible = true))
          }, {
            default: _withCtx(() => _cache[16] || (_cache[16] = [
              _createTextVNode(" 新增人员 ")
            ])),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _cache[14] || (_cache[14] = _createElementVNode("div", null, "关联人员", -1)),
            _createVNode(_component_el_button, {
              type: "text",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.visible = false))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, {
                  size: 15,
                  color: "#000"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_close)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_el_form, {
            ref: "form",
            model: _ctx.addForm,
            rules: _ctx.rules,
            onSubmit: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["prevent"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: "人员:",
                prop: "name"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_autocomplete, {
                    modelValue: _ctx.addForm.name,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.addForm.name) = $event)),
                    class: "inline-input",
                    "trigger-on-focus": "",
                    debounce: 100,
                    "fetch-suggestions": _ctx.queryMember,
                    placeholder: "人员",
                    onSelect: _ctx.handleMemberSelect
                  }, null, 8, ["modelValue", "fetch-suggestions", "onSelect"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, { label: "CodeReviewer:" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_checkbox, {
                    modelValue: _ctx.addForm.is_code_reviewer,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.addForm.is_code_reviewer) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules"]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_el_button, {
              type: "primary",
              size: "small",
              onClick: _ctx.addMember
            }, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createTextVNode(" 确定 ")
              ])),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        _: 1
      }, 8, ["visible"])
    ]),
    _createVNode(_component_el_dialog, {
      title: _ctx.controlDialog.title,
      "model-value": _ctx.controlDialog.isShow,
      width: "30%",
      "before-close": _ctx.beforeCloseDialog,
      center: "",
      onClose: _cache[12] || (_cache[12] = ($event: any) => (_ctx.controlDialog.isShow = false))
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_5, [
          _createVNode(_component_el_button, {
            onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.controlDialog.isShow = false))
          }, {
            default: _withCtx(() => _cache[17] || (_cache[17] = [
              _createTextVNode("取 消")
            ])),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _ctx.submitEditForm
          }, {
            default: _withCtx(() => _cache[18] || (_cache[18] = [
              _createTextVNode("确 定")
            ])),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          ref: "editForm",
          model: _ctx.editFormBody,
          rules: _ctx.editFormRules,
          "label-width": "100px",
          onSubmit: _cache[10] || (_cache[10] = _withModifiers(() => {}, ["prevent"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "难度:",
              prop: "degree_of_difficult"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input_number, {
                  modelValue: _ctx.editFormBody.degree_of_difficult,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editFormBody.degree_of_difficult) = $event)),
                  style: {"width":"300px"},
                  size: "small",
                  controls: false,
                  precision: 1,
                  min: 1,
                  max: 5
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "工作量:",
              prop: "cost_day"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input_number, {
                  modelValue: _ctx.editFormBody.cost_day,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editFormBody.cost_day) = $event)),
                  style: {"width":"300px"},
                  size: "small",
                  min: 1,
                  controls: false,
                  precision: 0,
                  placeholder: "包括设计排期、开发、联调的阶段的总工作日"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }, 8, ["title", "model-value", "before-close"])
  ]))
}