
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    // 分页信息
    formInline: {
      type: Object,
      default: () => {},
    },
    // 总数
    total: {
      type: Number,
      default: 0,
    },
  },
  emits: ['pageChange'],
  setup(props, ctx) {
    // 页数修改
    const handleCurrentChange = (currentPage: number): void => { ctx.emit('pageChange', currentPage); };
    return {
      handleCurrentChange,
    };
  },
});
