
import { getFollowUpList, getUserStage } from '@/api';
import { Message } from '@/common/utils';
import { FollowData, FollowUpRow } from '@/types/task/followup';
import { HeaderRow } from '@/types/task/header';
import {
  defineComponent, onMounted, reactive, ref,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useHeaderRows } from '@/hooks/useHeaderRows';
import DetailHeader from './components/detail-header.vue';
import FollowTable from './components/follow-table.vue';

export default defineComponent({
  components: {
    DetailHeader,
    FollowTable,
  },
  setup() {
    const [route, router] = [useRoute(), useRouter()];
    /*
     * 自动填充所需的信息 从路由上取
     * id：迭代id
     * uid：用户id
     * sid：阶段id
     */
    const { id = '', uid = '', sid = '' } = route.query;
    const options = reactive<Omit<FollowData, 'work'>>({
      users: [],
      stages: [],
    }); // 用户 & 阶段下拉框选项
    const headerRows = ref<Array<HeaderRow>>([]); // 页头迭代信息
    const formInline = ref({
      stage_id: null as null | number, // 默认没有的情况
      user_id: null as null | number, // 默认没有的情况
      status: 'active',
    });
    const tableData = ref<Array<FollowUpRow>>([]);
    const tabs = [
      { label: '正常', name: 'active' },
      { label: '已删除', name: 'deleted' },
    ]; // 渲染tabs数组

    // 获取数据
    const search = async () => {
      const { code, data } = await getFollowUpList(id, formInline.value);
      if (code === 0) {
        tableData.value = data.follow_ups;
        headerRows.value = useHeaderRows(data.work, ['member', 'stage', 'create', 'taskBug']);
      }
    };

    // 搜索当前迭代相关信息
    const searchMeta = async () => {
      const { code, data } = await getUserStage(id);
      if (code === 0) {
        headerRows.value = useHeaderRows(data.work, []);
        options.users = data.users;
        options.stages = data.stages;
        // 如果有uid 则代表是从人员列表进入的
        if (uid) {
          formInline.value.user_id = +uid;
        }
        // 如果有sid 则代表是从阶段列表进入的
        if (sid) {
          formInline.value.stage_id = +sid;
        }
        await search();
      } else {
        Message('获取迭代信息失败', 'error');
      }
    };

    const goCreateFollow = () => {
      router.push(`/create-follow?id=${id}`);
    }; // 新建跟进

    onMounted(() => {
      searchMeta();
    });

    return {
      tabs,
      tableData,
      search,
      formInline,
      headerRows,
      goCreateFollow,
      options,
    };
  },
});
