import { CrudOptions } from '@/components/Crud/crud.type';
import { getWorkBugList } from '@/api';
import { useHeaderRows } from '@/hooks/useHeaderRows';
import { ref, computed } from 'vue';
import { HeaderRow } from '@/types/task/header';
import router from '@/router';
import { BugLevelEnum, BugStageEnum } from '@/common/enum';
import { convertFloatInInt, Message } from '@/common/utils';
import { TaskInWorkBody } from '@/modules/task/taskBug/type';

// 任务id
const id = computed(() => router.currentRoute.value.query.id || '');

// 页头迭代信息
const headerRows = ref<Array<HeaderRow>>([]);

// 迭代信息
const work = ref<null | TaskInWorkBody>(null);

// 获取列表
const searchList = async (formInline: any) => {
  if (!id.value) return;
  const { code, data } = await getWorkBugList(id.value, formInline);
  if (code === 0) {
    work.value = data.work;
    headerRows.value = useHeaderRows(data.work, ['follow', 'stage', 'member', 'create']);
    return {
      tableList: data.work_user_bugs,
    };
  }
  Message('获取失败', 'error');
  return;
};

export const useCrud = () => {
  // 配置模块
  const options: CrudOptions = {
    fetch: searchList,
    column: [
      {
        label: 'id',
        prop: 'id',
        width: 90,
      },
      {
        label: '人员名称',
        prop: 'user_name',
        width: 100,
      },
      {
        label: '阶段',
        prop: 'stage',
        width: 60,
        enum: BugStageEnum,
      },
      {
        label: '等级',
        prop: 'level',
        width: 60,
        enum: BugLevelEnum,
      },
      {
        label: '数量',
        prop: 'count',
        width: 60,
        format: convertFloatInInt,
      },
      {
        label: '备注',
        prop: 'remark',
        slot: true,
      },
      {
        label: '添加信息',
        prop: 'userInfo',
        slot: true,
        width: 170,
      },
      {
        label: '操作',
        prop: 'handleBtn',
        slot: true,
        width: 60,
      },
    ],
  };
  return {
    options,
    headerRows,
    work,
  };
};
