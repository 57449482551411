
import { HeaderRow } from '@/types/task/header';
import {
  defineComponent, PropType, reactive, ref
} from 'vue';
import type { FormRules } from 'element-plus'
import { finishWork } from '@/api'
import { Message } from '@/common/utils';

interface RuleForm {
  design_url: string
}

export default defineComponent({
  props: {
    // 页头渲染所需格式
    rows: {
      type: Array as PropType<Array<HeaderRow>>,
      required: true,
      default: () => [],
    },
  },
  emits: ['refresh'],
  setup(props, ctx) {
    const formRef = ref()

    const isDocUrlEdit = ref(false)

    const docUrlForm = reactive({
      design_url: ''
    })

    const rules = reactive<FormRules<RuleForm>>({
      design_url: [{
        type: 'url',
        required: true,
        trigger: 'blur',
        validator: (rule: any, value: any) => {
          if (!/^(https?:\/\/[^\s/$.?#].[^\s]*)?$|^$/.test(`${value}`)) {
            return new Error('请输入正确URL')
          }
          if (`${value}`.length > 256) {
            return new Error('URL长度不能超过256')
          }
          return true
        },
      }]
    })

    const handleConfirmDocUrl = (row: { designUrl: string; workId: string; [k:string]: any}) => {
      if (!isDocUrlEdit.value) {
        isDocUrlEdit.value = true
        docUrlForm.design_url = row.designUrl
        return
      }
      if (!row?.workId) return
      formRef.value[0].validate(async (valid: any) => {
        if (valid) {
          const { code } = await finishWork(row?.workId, {
            design_url: docUrlForm.design_url
          });
          if (code === 0) {
            Message('修改成功');
            isDocUrlEdit.value = false
            ctx.emit('refresh')
          } else {
            Message('修改失败', 'error');
          }
        }
      })
    }

    const handleUrlClick = (row: { designUrl: string; workId: string; [k:string]: any}) => {
      if (row.designUrl) {
        window.open(row.designUrl)
      }
    }

    return {
      isDocUrlEdit,
      docUrlForm,
      rules,
      handleConfirmDocUrl,
      formRef,
      handleUrlClick
    }
  }
});
