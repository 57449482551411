
import { getUser, getUserWork } from '@/api';
import { Message } from '@/common/utils';
import { Member } from '@/types/task/member';
import {
  defineComponent, onMounted, reactive, ref,
} from 'vue';
import { useRouter } from 'vue-router';
import Pagination from '@/components/pagination.vue';
import { useDateFormat } from '@/hooks/useDateFormat';
import { UserWorkRow } from '@/types/personnel/current-work';
import dayUtils from '@/common/dayUtils';
import { TaskTypeEnum as typeList } from '@/common/enum';
import { useRolesList } from '@/hooks/useRolesList';
import CurrentTable from './components/current-work-table.vue';

export default defineComponent({
  components: {
    CurrentTable,
    Pagination,
  },
  setup() {
    const router = useRouter();

    // 获取角色列表
    const { roleList, remoteRolesList } = useRolesList();

    // 搜索条件表单
    const formInline = reactive({
      user_id: null as null | number, // 默认没有的情况
      type: '',
      user_type: '',
    });
    /* 任务时间选择器 */
    const datePicker = ref<Array<string>>([
      dayUtils.startOf('week').add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
      dayUtils.endOf('week').add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
    ]);
    /* 完成时间选择器 */
    const finishDatePicker = ref<Array<string>>([
      dayUtils.startOf('week').add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
      dayUtils.endOf('week').add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
    ]);
    // 模糊匹配自动填充字段
    const autoComplete = reactive({
      userName: '',
    });
    const tableData = ref<Array<UserWorkRow>>([]);
    /*
    * 缓存的两个数组来进行后期的表单校验
    * memberArr：模糊匹配用户列表
    */
    const memberArr = ref<Array<Member & { value: string }>>([]);
    // 过滤是否从下拉选择中获取用户信息
    const filterUser = (rule: any, value: string, callback: (...args: any) => any) => {
      const index = memberArr.value.findIndex(member => member.value === autoComplete.userName);
      if (autoComplete.userName && index === -1) {
        callback(new Error('请从下拉选择中添加人员'));
      } else {
        callback();
      }
    };
    // form表单规则
    const rules = reactive({
      userName: [
        { validator: filterUser },
      ],
    });

    const tabs = [
      { label: '正常', name: 'active' },
      { label: '已删除', name: 'deleted' },
    ]; // 渲染tabs数组

    // 分页信息
    const pageInfo = reactive({
      currentPage: 1,
      pageSize: 10,
      total: 0,
    });

    // 获取数据
    const search = async () => {
      // 因为这里提交的搜索请求需要有具体的user_id/stage_id 所以要用文本框的值来匹配是否选取模糊搜索结果中回调的id
      const findMember = memberArr.value.findIndex(member => member.value === autoComplete.userName);
      if (findMember === -1) {
        formInline.user_id = null;
      }
      /* 格式化任务时间 */
      let [started_at, finished_at] = datePicker.value || [];
      started_at = started_at ? useDateFormat(started_at) : '';
      finished_at = finished_at ? useDateFormat(finished_at) : '';

      /* 格式化完成时间 */
      let [complete_started_at, complete_finished_at] = finishDatePicker.value || [];
      complete_started_at = complete_started_at ? useDateFormat(complete_started_at) : '';
      complete_finished_at = complete_finished_at ? useDateFormat(complete_finished_at) : '';

      const { code, data } = await getUserWork({
        ...formInline,
        started_at,
        finished_at,
        complete_started_at,
        complete_finished_at,
        type: formInline.type || null,
        page: pageInfo.currentPage,
        count: pageInfo.pageSize,
      });
      if (code === 0) {
        pageInfo.total = data.total_count;
        tableData.value = data.work.map(item => ({
          ...item,
          isShowEdit: false,
        }));
      }
    };

    const queryParams = reactive({
      page: 1,
      count: 10,
    }); // 模糊搜索字段

    // 分页回调
    const pageChange = ({ currentPage }: { currentPage: number }): void => {
      pageInfo.currentPage = currentPage;
      search();
    };

    // 获取模糊匹配用户信息
    const queryMember = async (name: string, cb: (...args: any) => any) => {
      const { code, data } = await getUser({
        ...queryParams,
        name,
        status: 'active',
      });
      if (code === 0) {
        // 缓存起来 用于表单校验
        memberArr.value = data.users.map(member => ({
          ...member,
          value: member.name,
        }));
        cb(memberArr.value);
      } else {
        Message('获取用户失败', 'error');
      }
    };

    const handleMemberSelect = (item: Member) => {
      // 在没有相关模糊搜索下 将id置为null
      formInline.user_id = item.id || null;
    };

    // 重新筛选数据
    const refresh = (): void => {
      pageInfo.currentPage = 1;
      search();
    };

    const goExport = () => {
      let [started_at, finished_at] = datePicker.value || [];
      started_at = started_at ? useDateFormat(started_at) : '';
      finished_at = finished_at ? useDateFormat(finished_at) : '';

      const payload = {
        ...formInline,
        started_at,
        finished_at,
        type: formInline.type || null,
        page: pageInfo.currentPage,
        count: pageInfo.pageSize,
      };
      localStorage.setItem('exportContent', JSON.stringify(payload));
      const path = router.resolve({
        path: '/export',
      });
      window.open(path.href, '_blank');
    };

    onMounted(() => {
      remoteRolesList();
      search();
    });

    return {
      roleList,
      tabs,
      tableData,
      rules,
      queryMember,
      handleMemberSelect,
      pageInfo,
      autoComplete,
      typeList,
      formInline,
      search,
      refresh,
      pageChange,
      goExport,
      datePicker,
      finishDatePicker,
    };
  },
});
