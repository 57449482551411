import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_mdView = _resolveComponent("mdView")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!
  const _component_lx_crud = _resolveComponent("lx-crud")!
  const _directive_auth = _resolveDirective("auth")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_lx_crud, {
      ref: "crud",
      options: _ctx.options,
      immediate: "",
      pagination: ""
    }, {
      user_typeSearchSlot: _withCtx((formInline) => [
        _createVNode(_component_el_select, {
          modelValue: formInline.props.user_type,
          "onUpdate:modelValue": ($event: any) => ((formInline.props.user_type) = $event),
          clearable: "",
          placeholder: "角色",
          onChange: ($event: any) => (_ctx.roleChange($event, formInline))
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roleList, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.code,
                label: item.name,
                value: item.code
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 2
        }, 1032, ["modelValue", "onUpdate:modelValue", "onChange"])
      ]),
      kpi_idSearchSlot: _withCtx((formInline) => [
        _createVNode(_component_el_select, {
          modelValue: formInline.props.kpi_id,
          "onUpdate:modelValue": ($event: any) => ((formInline.props.kpi_id) = $event),
          clearable: "",
          placeholder: "考核项",
          disabled: !formInline.props.user_type
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.kpiList, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.id,
                label: item.name,
                value: item.id
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 2
        }, 1032, ["modelValue", "onUpdate:modelValue", "disabled"])
      ]),
      scoreTableSlotHeader: _withCtx(() => [
        _createElementVNode("div", {
          class: "can-pointer",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setHiddenStatus(!_ctx.hiddenStatus)))
        }, [
          _cache[1] || (_cache[1] = _createTextVNode(" 评分")),
          _createVNode(_component_svg_icon, {
            "icon-class": _ctx.hiddenStatus? 'eye-hide': 'eye-show'
          }, null, 8, ["icon-class"])
        ])
      ]),
      scoreTableSlot: _withCtx((scope) => [
        _createElementVNode("div", null, _toDisplayString(_ctx.hiddenStatus ? '*' : scope.row.score), 1)
      ]),
      remarkTableSlot: _withCtx((scope) => [
        _createVNode(_component_mdView, {
          index: scope.$index,
          content: scope.row.remark
        }, null, 8, ["index", "content"])
      ]),
      userInfoTableSlot: _withCtx((scope) => [
        _createElementVNode("div", null, _toDisplayString(scope.row.user_name || '--'), 1),
        _createElementVNode("div", null, _toDisplayString(scope.row.created_at ? _ctx.dayjs(scope.row.created_at).format('YYYY-MM-DD') : '--'), 1)
      ]),
      handleBtnTableSlot: _withCtx((scope) => [
        _createElementVNode("div", null, [
          _createVNode(_component_el_popover, {
            placement: "left",
            width: 200,
            trigger: "click",
            "auto-close": 1500
          }, {
            reference: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "text",
                size: "small"
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode(" 查看 ")
                ])),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createElementVNode("div", null, "创建人： " + _toDisplayString(scope.row.creator_name), 1),
                (scope.row.deletor_name)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, " 删除人： " + _toDisplayString(scope.row.deletor_name), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 2
          }, 1024)
        ]),
        (scope.row.status_code === 'active')
          ? (_openBlock(), _createBlock(_component_el_popconfirm, {
              key: 0,
              "confirm-button-text": "确定",
              "cancel-button-text": "取消",
              icon: _ctx.InfoFilled,
              "icon-color": "red",
              title: "确认删除该跟进",
              onConfirm: ($event: any) => (_ctx.confirmDelete(scope.row))
            }, {
              reference: _withCtx(() => [
                _withDirectives((_openBlock(), _createBlock(_component_el_button, {
                  type: "text",
                  size: "small"
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode(" 删除 ")
                  ])),
                  _: 1
                })), [
                  [_directive_auth]
                ])
              ]),
              _: 2
            }, 1032, ["icon", "onConfirm"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["options"])
  ]))
}