
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-extraneous-dependencies */
import {
  defineComponent, ref, onMounted, reactive,
} from 'vue';
import Editor from '@toast-ui/editor';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight';

// markdown所需的css样式
import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'highlight.js/styles/github.css';

export default defineComponent({
  name: 'MdEditor',
  props: {
    modelValue: {
      type: String,
      required: true,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(_, { emit }) {
    const editor = ref();
    let e: any = reactive({});
    /*
     * 这里因为官方包装的Vue组件是用到了2.x版本的API 所以不兼容3.0的情况 这里做了个jsx实现的polyfill
     * https://github.com/nhn/tui.editor
     */
    onMounted(() => {
      e = new Editor({
        el: editor.value, // markdown挂载的元素
        height: '500px', // markdown高度
        initialEditType: 'markdown', // 初始化编辑器类型
        previewStyle: 'vertical', // 查看方式 水平/垂直
        language: 'zh-cn', // 默认语言
        usageStatistics: false, // send hostname to google analytics
        plugins: [[codeSyntaxHighlight]] as any, // 使用的插件 这里只使用到了代码高亮
        // toolbar自定义选项
        toolbarItems: [
          'heading',
          'bold',
          'italic',
          'strike',
          'divider',
          'hr',
          'quote',
          'divider',
          'ul',
          'ol',
          'task',
          'indent',
          'outdent',
          'divider',
          'table',
          'link',
          'divider',
          'code',
          'codeblock',
          'divider',
        ],
        events: {
          // 定义回调事件
          change: () => emit('update:modelValue', e.getMarkdown()),
        },
      });
    });

    // 设置markdown内容
    const setMarkdown = (val: string) => {
      e.setMarkdown(val);
    };

    return {
      editor,
      setMarkdown,
    };
  },
});
