import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("div", {
    id: `lxOverPopup${_ctx.index}`,
    class: "lx-over-popup"
  }, [
    (!_ctx.isEllipsis)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createTextVNode(_toDisplayString(_ctx.propsContent), 1)
        ], 64))
      : (_ctx.overType === 'popover')
        ? (_openBlock(), _createBlock(_component_el_popover, {
            key: 1,
            placement: _ctx.placement,
            width: 200,
            trigger: _ctx.trigger
          }, {
            reference: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.propsContent), 1)
            ]),
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "lxOverPopupContent", {}, undefined, true)
            ]),
            _: 3
          }, 8, ["placement", "trigger"]))
        : (_ctx.overType === 'markdown')
          ? (_openBlock(), _createElementBlock("span", {
              key: 2,
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.emitClick && _ctx.emitClick(...args)))
            }, _toDisplayString(_ctx.propsContent), 1))
          : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}