import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "main-header" }
const _hoisted_2 = { class: "main-header__average" }
const _hoisted_3 = { class: "main-header__average" }
const _hoisted_4 = { class: "main-header__average" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Menus = _resolveComponent("Menus")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_ComplexityTable = _resolveComponent("ComplexityTable")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createBlock(_component_el_container, { class: "page-complexity-index" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_container, { class: "container-content" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_aside, {
            width: "200px",
            style: {"background-color":"rgb(238, 241, 246)"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Menus, { page: "complexity" })
            ]),
            _: 1
          }),
          _createVNode(_component_el_main, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("h1", null, _toDisplayString(_ctx.name), 1),
                _createElementVNode("div", _hoisted_2, " 复杂度平均值：" + _toDisplayString(_ctx.info.average), 1),
                _createElementVNode("div", _hoisted_3, " 总函数数：" + _toDisplayString(_ctx.info.functions), 1),
                _createElementVNode("div", _hoisted_4, " 总文件数：" + _toDisplayString(_ctx.info.files), 1)
              ]),
              _createVNode(_component_el_tabs, {
                modelValue: _ctx.renderRule,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.renderRule) = $event)),
                type: "card",
                onTabChange: _ctx.switchTab
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
                    return (_openBlock(), _createBlock(_component_el_tab_pane, {
                      key: tab.name,
                      label: tab.label,
                      name: tab.name
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ComplexityTable, {
                          message: _ctx.message,
                          "table-data": _ctx.tableData
                        }, null, 8, ["message", "table-data"])
                      ]),
                      _: 2
                    }, 1032, ["label", "name"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue", "onTabChange"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}