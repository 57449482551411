
import { deleteFollowUp } from '@/api';
import mdView from '@/components/Markdown/MdView.vue';
import { Message } from '@/common/utils';
import { FollowUpRow } from '@/types/task/followup';
import { computed, defineComponent, PropType } from 'vue';
import { useRouter } from 'vue-router';
import { StatusType } from '@/types';
import dayjs from 'dayjs';
import { useStore } from 'vuex';

export default defineComponent({
  components: {
    mdView,
  },
  props: {
    // 当前状态
    status: {
      type: String as PropType<StatusType>,
      required: true,
      default: 'active',
    },
    // 表格数据
    tableData: {
      type: Array as PropType<Array<FollowUpRow>>,
      required: true,
      default: () => [],
    },
  },
  emits: ['refresh'],
  setup(_, { emit }) {
    const store = useStore();
    const router = useRouter();
    // 弹出查看markwodn正文结构
    const goCreate = () => { router.push('/create'); }; // 新建工作任务

    // 确认删除
    const confirmDelete = async ({ id: cid, work_id }: FollowUpRow) => {
      const { code } = await deleteFollowUp(work_id, cid);
      if (code === 0) {
        Message('删除成功');
        emit('refresh');
      } else {
        Message('删除失败', 'error');
      }
    };

    // 继续跟进
    const continueFollow = async ({ work_id, user_id = '', stage_id = '' }: FollowUpRow) => {
      router.push({
        name: 'CreateFollow',
        query: {
          id: work_id,
          uid: user_id,
          sid: stage_id,
        },
      });
    };

    // 处理评分隐藏
    const hiddenStatus = computed(() => store.getters['setting/getScoreHiddenStatus']);
    const setHiddenStatus = () => {
      store.commit('setting/switchScoreHideStatus');
    };
    return {
      goCreate,
      confirmDelete,
      continueFollow,
      dayjs,
      hiddenStatus,
      setHiddenStatus,
    };
  },
});
