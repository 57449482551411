import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "current-work-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_table, {
      data: _ctx.tableData,
      border: "",
      size: "medium",
      class: "table",
      "row-class-name": "table__column"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          label: "#",
          "min-width": "15"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", {
              class: _normalizeClass(['status-code', scope.row.status_code])
            }, null, 2)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "id",
          label: "id",
          align: "center"
        }),
        _createVNode(_component_el_table_column, {
          label: "人员",
          align: "center"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_button, {
              type: "text",
              size: "medium",
              onClick: ($event: any) => (_ctx.goPersonnel(scope.row.user_id))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(scope.row.user_name), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createElementVNode("div", null, _toDisplayString(scope.row.user_role), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "名称",
          align: "center",
          "min-width": "200"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_button, {
              type: "text",
              size: "medium",
              onClick: ($event: any) => (_ctx.goTask(scope.row.id))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(scope.row.work_name), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "work_type",
          label: "类型",
          align: "center"
        }),
        _createVNode(_component_el_table_column, {
          label: "工期",
          align: "center"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", null, _toDisplayString(scope.row.started_at || '--'), 1),
            _createElementVNode("div", null, _toDisplayString(scope.row.finished_at || '--'), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "创建信息",
          prop: "type",
          align: "center"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", null, _toDisplayString(scope.row.creator_name || '--'), 1),
            _createElementVNode("div", null, _toDisplayString(scope.row.created_at || '--'), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"])
  ]))
}