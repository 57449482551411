import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "page-follow-up" }
const _hoisted_2 = { class: "tab-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_detail_header = _resolveComponent("detail-header")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_follow_table = _resolveComponent("follow-table")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _directive_auth = _resolveDirective("auth")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_detail_header, {
      rows: _ctx.headerRows,
      onRefresh: _ctx.search
    }, null, 8, ["rows", "onRefresh"]),
    _createVNode(_component_el_form, {
      inline: "",
      model: _ctx.formInline,
      class: "form-inline"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: "人员：",
          prop: "user_id"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              modelValue: _ctx.formInline.user_id,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formInline.user_id) = $event)),
              clearable: "",
              placeholder: "请选择人员"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options.users, (item) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: item.id,
                    label: item.name,
                    value: item.id
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "阶段：",
          prop: "stage_id"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              modelValue: _ctx.formInline.stage_id,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formInline.stage_id) = $event)),
              clearable: "",
              placeholder: "请选择阶段"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options.stages, (item) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: item.id,
                    label: item.name,
                    value: item.id
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _ctx.search
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" 查询 ")
              ])),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_tabs, {
        modelValue: _ctx.formInline.status,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formInline.status) = $event)),
        type: "card",
        onTabChange: _ctx.search
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
            return (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: tab.name,
              label: tab.label,
              name: tab.name
            }, {
              default: _withCtx(() => [
                _createVNode(_component_follow_table, {
                  "table-data": _ctx.tableData,
                  status: _ctx.formInline.status,
                  onRefresh: _ctx.search
                }, null, 8, ["table-data", "status", "onRefresh"])
              ]),
              _: 2
            }, 1032, ["label", "name"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue", "onTabChange"]),
      _withDirectives((_openBlock(), _createBlock(_component_el_button, {
        type: "primary",
        class: "header",
        plain: "",
        onClick: _ctx.goCreateFollow
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode(" 新增跟进 ")
        ])),
        _: 1
      }, 8, ["onClick"])), [
        [_directive_auth]
      ])
    ])
  ]))
}