import { ref } from 'vue';
import { getUser, getUserWorkFollowups } from '@/api';
import { Message } from '@/common/utils';
import store from '@/store/index';

// 列表页获取
const searchPersonnelFollow = async (params: any) => {
  const reqData = { ...params };
  delete reqData.created_times;
  const { code, data } = await getUserWorkFollowups(reqData);
  if (code !== 0) {
    Message('获取失败', 'error');
    return;
  }
  return {
    tableList: data.follow_ups,
    totalNum: data.total_count,
  };
};

// 获取用户列表
const queryMember = async (query: string | number) => {
  const { code, data } = await getUser({
    page: 1,
    count: 10,
    name: query,
    status: 'active',
  });
  if (code === 0) {
    return data.users.map(el => ({
      value: el.id,
      label: el.name,
    }));
  }
  Message('获取用户失败', 'error');
};

export const useCrud = () => {
  const options = ref({
    title: '人员跟进列表',
    fetch: searchPersonnelFollow,
    tabs: [
      {
        value: 'active',
        label: '正常',
        default: true,
      },
      {
        value: 'deleted',
        label: '已删除',
      },
    ],
    column: [
      {
        label: 'id',
        prop: 'id',
        width: 80,
      },
      {
        label: '人员',
        prop: 'user_origin_id',
        type: 'select',
        search: true,
        hidden: true,
        remoteMethod: queryMember,
        defaultValue: store.getters['user/getCurrentUserDetail'].id,
        enum: [{ label: store.getters['user/getCurrentUserDetail'].name, value: store.getters['user/getCurrentUserDetail'].id }],
      },
      {
        label: '创建人',
        prop: 'creator_id',
        type: 'select',
        search: true,
        hidden: true,
        remoteMethod: queryMember,
      },
      {
        label: '角色',
        prop: 'user_type',
        type: 'slot',
        search: true,
        hidden: true,
      },
      {
        label: '考核项',
        prop: 'kpi_id',
        type: 'slot',
        search: true,
        hidden: true,
      },
      {
        label: '创建时间',
        prop: 'created_times',
        type: 'date',
        search: true,
        hidden: true,
        searchKey: [
          'created_start_at',
          'created_end_at',
        ],
      },
      {
        label: '标题',
        prop: 'title',
        overTooltip: true,
        minWidth: 200,
      },
      {
        label: '任务',
        prop: 'work_name',
        overTooltip: true,
        minWidth: 200,
      },
      {
        label: '跟进内容',
        prop: 'remark',
        slot: true,
        minWidth: 400,
      },
      {
        label: '评分',
        prop: 'score',
        slot: true,
        slotHeader: true,
        width: 70,
      },
      {
        label: '关联人员',
        prop: 'userInfo',
        slot: true,
        width: 110,
      },
      {
        label: '关联阶段',
        prop: 'stage_name',
        width: 110,
      },
      {
        label: '操作',
        prop: 'handleBtn',
        slot: true,
        width: 80,
      },
    ],
  });
  return {
    options,
  };
};
